import { Button, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// import { ExclamationCircleOutlined } from '@ant-design/icons';

import AdminPage from '../../components/adminPage';
import ShiftModal from '../../components/shiftModal';

import { formatPrice, dateToString } from '../../lib/utils/index';
// import { cancelSell } from '../../lib/service/sell';
import { listUser } from '../../lib/service/user';

const Screen = () => {

    const { user, accessToken } = useSelector(state => state.auth);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        attempGetUsers();
    }, []);

    const attempGetUsers = async () => {
        try {
            const { status, data } = await listUser(accessToken, { pagination: { pageSize: 99 } });
            if (status !== 200) return;
            setUsers(data.data);
        } catch (error) {

        }
    }

    const pageTitle = "Cortes de Caja";

    const renderCtrls = (_, record, i, openRecord, showPromiseConfirm, reloadTable) => {
        // const options = {
        //     title: `¿Esta seguro de cancelar la venta con folio: ${record.id}?`,
        //     icon: <ExclamationCircleOutlined />,
        //     content: 'Esta es una acción que no se puede deshacer.',
        //     onOk() {
        //         return cancelSell(accessToken, { sell: { id: record.id } })
        //             .then(({ status, data }) => {
        //                 if (status !== 200 || data.error) {
        //                     if (data.error?.code === 'SHIFT_NOT_ACTIVE')
        //                         message.error('No es posible cancelar estas venta. El turno ha terminado.');
        //                     else
        //                         showMessageError();
        //                     return;
        //                 }
        //                 message.success(`La venta ha sido cancelada con éxito.`);
        //                 reloadTable();
        //             })
        //             .catch(() => {
        //                 showMessageError();
        //             });
        //     },
        //     onCancel() { },
        // }
        return (
            <>
                <Button onClick={() => openRecord(record)}>Ver</Button>
            </>
        );
    }

    const columns = [
        {
            key: 'id',
            title: 'Folio'
        },
        {
            key: 'user',
            title: 'Usuario',
            render: (_, record) => {
                return (
                    <>
                        <Tag>{record.User.firstName} {record.User.lastName}</Tag><br />
                        <Tag>{record.Gym.name} - {record.Gym.branch}</Tag>
                    </>
                )
            }
        },
        {
            key: 'startAt',
            title: 'Fecha de Inicio',
            render: (value) => {
                let date = new Date(value);
                return dateToString(date, true);
            }
        },
        {
            key: 'endAt',
            title: 'Fecha de Terminación',
            render: (value) => {
                if (!value) return '-';
                let date = new Date(value);
                return dateToString(date, true);
            }
        },
        {
            key: 'status',
            title: 'Estado',
            render: (value) => {
                if (value === 'closed') return (<Tag color="red">Cerrado</Tag>);
                if (value === 'active') return (<Tag color="green">Abierto</Tag>);
                return value;
            }
        },
        {
            key: 'cashAtEnd',
            title: 'Efectivo',
            render: (value, record) => {
                let offset = record.cash - value;
                return (
                    <>
                        {formatPrice(value)}<br />
                        {offset > 0 && (<Tag color="red">Efectivo faltante: {formatPrice(offset)}</Tag>)}
                    </>
                )
            }
        },
        {
            key: 'controls',
            title: 'Controles',
            align: 'right',
            render: renderCtrls
        },
    ];

    const filters = [
        {
            key: 'gymId',
            title: 'Gimnasio',
            options: user?.gyms?.map((gym) => {
                return { value: gym.id, label: `${gym.name} - ${gym.branch}` };
            }) ?? []
        },
        {
            key: 'userId',
            title: 'Usuario',
            options: users.map((user, index) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))
        }
    ];

    const endpoint = '/shifts/list';

    return (
        <AdminPage
            pageTitle={pageTitle}
            columns={columns}
            filters={filters}
            endpoint={endpoint}
            showAddButton={false}
            Modal={ShiftModal}
        />
    );
}

export default Screen;