import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import Agenda from './agenda';
import Profile from './profile';

const AgendaScreen = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${url}`}>
                <Agenda />
            </Route>
            <Route exact path={`${url}/:classId/:date`}>
                <Profile />                                                                                                                                                                                                                                                                                                                                                                       
            </Route>
        </Switch>
    )

}

export default AgendaScreen;