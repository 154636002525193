import {
    Switch,
    Route,
    Redirect,
    useRouteMatch
} from "react-router-dom";

import RcptcScreen from '../../screens/rcptc';
import NotFoundScreen from '../../screens/notFound';

const Public = () => {

    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <NotFoundScreen />
            </Route>
            <Route exact path={`${path}/ticket/:id`}>
                <RcptcScreen />
            </Route>
            <Route path="*">
                <Redirect to={`${path}`} />
            </Route>
        </Switch>
    );
}

export default Public