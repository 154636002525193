import {
    useEffect,
    useState
} from 'react';

import {
    useSelector
} from 'react-redux';

import {
    Row,
    Col,
    Card,
    Form,
    Input,
    Button,
    message,
    Tabs,
    Avatar,
    Space
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import {
    useParams
} from "react-router-dom";

import * as CLIENT from '../../lib/service/clients';
import ClientMemberships from '../../components/clientMemberships';
import ClientAttendances from '../../components/clientAttendances';

const { TabPane } = Tabs;

const ProfileScreen = () => {

    const { accessToken } = useSelector(store => store.auth);
    const { code } = useParams();

    const [clientId, setClientId] = useState(null);
    const [clientImage, setClientImage] = useState(null);
    const [memberships, setMemberships] = useState([]);
    const [attendances, setAttendances] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        CLIENT.getByCode(accessToken, { code })
            .then(({ status, data }) => {
                if (status !== 200) {
                    return;
                }
                form.setFieldsValue({
                    firstName: data?.data?.firstName,
                    lastName: data?.data?.lastName,
                    phoneNumber: data?.data?.phoneNumber,
                    email: data?.data?.email,
                });
                setClientId(data.data.id);
                setMemberships(data?.data?.ClientMemberships ?? []);
                setAttendances(data?.data?.Attendances ?? []);
                setClientImage(data?.data?.image);
            })
            .catch(error => null);
    }, [code]);

    const updateClient = (fields) => {
        const client = { id: clientId, ...fields };
        CLIENT.updateClient(accessToken, { client })
            .then(({ status, data }) => message.success('El cliente fue actualizado con exito!'))
            .catch(error => null);
    }

    const updateMembership = (membersip) => {
        const newMemberships = [...memberships];
        newMemberships.forEach((element, index) => {
            if (element.id === membersip.id) {
                newMemberships[index] = {
                    ...element,
                    ...membersip
                }
            }
        });
        setMemberships(newMemberships);
    }

    return (
        <Row gutter={[24, 24]}>
            <Col span={12}>
                <Card title={"Información personal"} bordered={false}>
                    <Form form={form} onFinish={updateClient} layout="vertical">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Avatar size={128} src={clientImage} icon={<UserOutlined />} />
                                <br />
                                <br />
                            </Col>
                            <Col span={24}>
                                <Form.Item name="firstName" label="Nombre(s)">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="lastName" label="Apellido(s)">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="phoneNumber" label="Teléfono">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="email" label="Correo electrónico">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <div style={{ textAlign: 'right', width: '100%' }}>
                                <Button type="primary" htmlType="submit">Guardar</Button>
                            </div>
                        </Row>
                    </Form>
                </Card>
            </Col>
            <Col span={24}>
                <Card>
                    <Tabs defaultActiveKey="1" onChange={() => null}>
                        <TabPane tab="Membresías" key="1">
                            <ClientMemberships data={memberships} callbackFunc={updateMembership} />
                        </TabPane>
                        <TabPane tab="Asistencias" key="2">
                            <ClientAttendances data={attendances} />
                        </TabPane>
                    </Tabs>
                </Card>
            </Col>
        </Row>
    )
};

export default ProfileScreen;