import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Upload,
    notification,
    Select
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import * as GYM from './../../lib/service/gym';
import ImageDemo from './../../components/imageDemo';

const Basic = ({ gymId: id }) => {

    const { accessToken } = useSelector(store => store.auth);
    const [logo, setLogo] = useState({ src: 'error', file: undefined });
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            GYM.getGymById(accessToken, { id })
                .then(({ status, data }) => {
                    if (status === 200) {
                        const response = data.data;
                        console.log(response);
                        form.setFieldsValue({
                            name: response.gym.name,
                            branch: response.gym.branch,
                            rfc: response.gym.rfc,
                            businessName: response.gym.businessName,
                            phoneNumber: response.gym.phoneNumber,
                            address: response.gym.address,
                            status: response.gym.status
                        });
                        setLogo({ src: response.gym.logo, file: undefined });
                    }
                })
                .catch(err => {
                    return null;
                });
        }
    }, [id]);

    const updateClient = (fields) => {

        setLoading(true);

        let params = { gym: { id, ...fields } };

        if (logo.file) {
            const formData = new FormData();
            formData.append("logo", logo.file);
            formData.append("gym", JSON.stringify({ id, ...fields }));
            params = formData;
        }

        GYM.updateGym(accessToken, params)
            .then(({ status, data }) => {
                if (status === 200) {
                    notification.success({ message: 'Información actualizada.' });
                }
            })
            .catch(err => {
                notification.error({ message: 'Servicio no disponible.' });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Form form={form} onFinish={updateClient} layout="vertical">
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <ImageDemo src={logo.src} />
                        </Col>
                        <Col span={24}>
                            <Upload
                                fileList={[]}
                                onRemove={file => null}
                                beforeUpload={file => {
                                    const reader = new FileReader();
                                    reader.addEventListener("load", function () {
                                        setLogo({
                                            src: reader.result,
                                            file
                                        });
                                    }, false);
                                    if (file) reader.readAsDataURL(file);
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Selecciona Logo</Button>
                            </Upload>
                            <br />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'No olvides el Nombre!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="branch"
                                label="Sucursal"
                                rules={[{ required: true, message: 'No olvides la Sucursal!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="rfc" label="RFC"
                                rules={[{ required: true, message: 'No olvides el RFC!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="businessName" label="Razón Social"
                                rules={[{ required: true, message: 'No olvides la Sucursal!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="phoneNumber" label="Teléfono"
                                rules={[{ required: true, message: 'No olvides el Teléfono!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={18}>
                            <Form.Item name="address" label="Dirección" rules={[{ required: true, message: 'No olvides el Dirección!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="status" label="Estatus">
                                <Select
                                    options={[
                                        { value: 'active', label: 'Activo'},
                                        { value: 'inactive', label: 'Inactivo'},
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Button loading={loading} type="primary" htmlType="submit" style={{ float: 'right' }}>Guardar</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
};

export default Basic;