import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        logged: false,
        user: null,
        accessToken: null,
        shift: null
    },
    reducers: {
        signInUser: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const { payload } = action;
            state.logged = true;
            state.user = payload.user;
            state.accessToken = payload.accessToken;
            state.shift = payload.shift;
        },
        signOutUser: (state) => {
            state.logged = false;
            state.user = null;
            state.accessToken = null;
            state.shift = null;
        },
        setShift: (state, action) => {
            const { payload } = action;
            state.shift = payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    signInUser,
    signOutUser,
    setShift,
} = authSlice.actions

export default authSlice.reducer