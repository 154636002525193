import { Modal, Form, DatePicker, Radio, Row, Col, message } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import * as MEMBERSHIPS from '../../lib/service/membership';
import { showMessageError } from '../../lib/utils';

const ModalC = ({ membership, callbackFunc }) => {

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { accessToken } = useSelector(state => state.auth);

    useEffect(() => {
        if (membership) {
            form.setFieldsValue({
                endAt: moment(membership.endAt),
                status: membership.status
            });
        }
    }, [membership]);

    const onOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    setConfirmLoading(true);

                    const newValues = {
                        id: membership.id,
                        status: values.status,
                        endAt: values.endAt.format('YYYY-MM-DD'),
                    }
                    const { status, data } = await MEMBERSHIPS.updateClientMembership(accessToken, { membership: newValues });

                    setConfirmLoading(false);

                    if (status !== 200 || data.error) {
                        showMessageError();
                        return;
                    }

                    message.success("La Membresía ha sido actualizada.");
                    callbackFunc(newValues);
                    form.resetFields();

                } catch (error) {
                    setConfirmLoading(false);
                    showMessageError();
                }
            })
            .catch(info => null);
    }

    const onCancel = () => {
        form.resetFields();
        callbackFunc();
    }

    function disabledDate(current) {
        // Can not select days before today and today
        // return current && current < moment().endOf('day');
        return false;
    }

    return (
        <Modal
            confirmLoading={confirmLoading}
            visible={Boolean(membership)}
            title="Editar Membresía"
            okText="Continuar"
            cancelText="Cancelar"
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form
                form={form}
                layout="vertical"
            >

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="endAt"
                            label="Fecha de terminación"
                            rules={[{ required: true, message: 'No olvides la fecha!' }]}
                        >
                            <DatePicker
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="status"
                            label="Estatus"
                            initialValue={'active'}
                        >
                            <Radio.Group
                                options={[
                                    { label: 'Activa', value: 'active' },
                                    { label: 'Expirada', value: 'expired' },
                                ]}
                                optionType="button"
                                buttonStyle="solid"
                            ></Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
}

export default ModalC;