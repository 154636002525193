import { Row, Col } from 'antd';

import AddButton from './addButton';

const PageWrapper = ({ title, children, header, content, addButton }) => {
    return (
        <div style={{ margin: '-24px -16px 0px' }}>
            <div style={{ background: 'rgb(250, 250, 250)', borderBottom: '1px solid #e8e8e8', padding: '16px 32px 0px 32px' }}>

                <Row style={{ marginBottom: '16px' }}>
                    {/* <Col span={12}>
                        <Breadcrumb {...this.props} />
                    </Col> */}
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {header ? header : null}
                    </Col>
                </Row>

                {title && (
                    <Row style={{ marginBottom: '16px' }}>
                        <Col span={12}>
                            <h1 style={{ fontWeight: 50, fontSize: 20 }}>{title}</h1>
                        </Col>
                    </Row>)}

                {addButton && <AddButton onClick={addButton} />}

            </div>
            {children ? (<div style={{ margin: '24px' }}>{children}</div>) : null}
        </div>
    )
}

export default PageWrapper;