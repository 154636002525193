import './index.less';

import { Modal } from 'antd';

import SellDescription from '../sellDescription';

const ModalC = ({ visible, closeModal, record = {}, reloadTable }) => {

    const onCancel = () => {
        closeModal();
    }

    return (
        <Modal
            visible={visible}
            title="Venta"
            okText="Continuar"
            cancelText="Cancelar"
            footer={null}
            onCancel={onCancel}
        >
            <SellDescription record={record} />
        </Modal>
    );
}

export default ModalC;