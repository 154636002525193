import { Button, Tag } from "antd";
import AdminPage from "../../../components/adminPage";
import ProductsModal from '../../../components/productsModal';

const ClientsScreen = () => {

    const endpoint = '/products/list';

    const renderControls = (_, record, __, openRecord) => {
        return (<Button onClick={() => openRecord(record)}>Editar</Button>);
    }

    const renderStatus = (value) => {
        let color = 'green';
        let text = 'Activo';
        if (value === 'inactive') {
            color = 'magenta';
            text = 'Inactivo';
        }
        return (<Tag color={color}>{text}</Tag>)
    }

    const renderGyms = (value) => {
        return value.map(gymName => (<div key={`${gymName}`} style={{ marginBottom: '4px' }}><Tag>{gymName}</Tag></div>));
    }

    const columns = [
        { key: 'code', title: 'Código' },
        { key: 'name', title: 'Nombre' },
        { key: 'price', title: 'Precio' },
        { key: 'gyms', title: 'Gimnasios', render: renderGyms },
        { key: 'status', title: 'Estatus', render: renderStatus },
        { key: 'controls', title: 'Controles', align: 'right', render: renderControls }
    ];

    const filters = [
        { title: 'Código', key: 'code' },
        {
            key: 'status',
            title: 'Estatus',
            options: [
                { value: 'active', label: 'Activa' },
                { value: 'inactive', label: 'Inactiva' },
            ]
        },
    ];

    return (
        <AdminPage
            pageTitle="Productos y Servicios"
            columns={columns}
            endpoint={endpoint}
            filters={filters}
            showAddButton={true}
            Modal={ProductsModal}
        />
    )
}

export default ClientsScreen;