import './menu.less';

import { Input, message } from 'antd';

import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { userStatus } from '../../lib/service/sell';
import { updateClient } from '../../lib/service/clients';
import { formatPrice } from '../../lib/utils';

import Clock from './clock';
import Sell from './sell';
import EditMembership from './editMembership';
import SearchClient from './searchClient';
import TakePicture from './takePicture';
import OpenShift from './openShift';
import CloseShift from './closeShift';

const { TextArea } = Input;

const Menu = ({ goToScreen, returnToApp }) => {

    const { accessToken, user, shift } = useSelector(state => state.auth);

    const [code, setCode] = useState('');
    const [data, setData] = useState({});

    const [paymentMethod, setPaymentMethod] = useState('');
    const [comments, setComments] = useState('');
    const [newMembership, setNewMembership] = useState(null);
    const [newImage, setNewImage] = useState(null);

    const [openEditMembershipVisible, setOpenEditMembershipVisible] = useState(false);
    const [sellIsVisible, setSellIsVisible] = useState(false);
    const [isSearchClientVisible, toggleSearchClient] = useState(false);
    const [cameraIsOpen, toggleCamera] = useState(false);
    const [isCloseShiftOpen, toggleCloseShift] = useState(false);

    const refSubmitButton = useRef(null);

    const handleOnSignout = () => {
        closeShiftModal.open();
    }

    /**
     * Shift methods 
     */
    const closeShiftModal = {
        open: () => toggleCloseShift(true),
        close: (loggedOut = false) => {
            toggleCloseShift(false);
            if (loggedOut) returnToApp();
        },
        hanldeOnSuccess: () => {

        }
    };

    /**
     * Sell methods
     */
    const openSell = (paymentMethod) => {
        if (!data?.client) {
            alert('PRIMERO SELECCIONA UN CLIENTE');
            return;
        }
        setPaymentMethod(paymentMethod);
        setSellIsVisible(true);
    }

    const closeSell = () => {
        setSellIsVisible(false);
    }

    const handleOnSuccessSell = () => {
        refSubmitButton.current.click();
    }

    /**
     * EditMembership methods
     */
    const openEditMembership = () => {
        if (newMembership) {
            setNewMembership(null);
            return;
        }
        if (!data?.client) {
            alert('PRIMERO SELECCIONA UN CLIENTE');
            return;
        }
        setOpenEditMembershipVisible(true);
    }

    const closeEditMembership = () => {
        setOpenEditMembershipVisible(false);
    }

    const handleOnEditMembership = (membership) => {
        setNewMembership(membership);
    }

    /**
     * searchClient methods
     */
    const searchClient = {
        open: () => toggleSearchClient(true),
        close: () => toggleSearchClient(false),
        userPicked: (code) => {
            setCode(code);
            setTimeout(() => attemptSearchClient(code), 300)
        }
    }

    /**
     * Camera methods
     */
    const camera = {
        open: function () {
            toggleCamera(true);
        },
        close: function () {
            toggleCamera(false);
        },
        setPicture: function (imgDataUrl) {
            setNewImage(imgDataUrl);
            attemptUpdateClient({ image: imgDataUrl });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let cleanCode = code.trim();
        attemptSearchClient(cleanCode);
    }

    const attemptSearchClient = async (code) => {
        if (code === '') {
            alert('INTRODUCE UN CODIGO VALIDO');
            return;
        }
        try {
            setNewMembership(null);
            setNewImage(null);
            const { status, data = {} } = await userStatus(accessToken, { code });
            if (status !== 200) {
                return;
            } else if (data.error) {
                setData({});
            } else {
                setData(data);
                setComments(data?.client?.comments);
            }
        } catch (error) { }
    }

    const attemptSaveComments = () => {
        attemptUpdateClient({ comments });
    }

    const attemptUpdateClient = async (values) => {
        try {
            const { status } = await updateClient(accessToken, { client: { ...values, id: data?.client?.id } });
            if (status !== 200) {
                return;
            }
            message.success('La información del cliente han sido actualizada');
        } catch (error) {
        }
    }

    const membershipForSell = newMembership || data.membership;

    return (
        <div className="kioskoMenu">
            <OpenShift />
            <CloseShift
                visible={isCloseShiftOpen}
                onClose={closeShiftModal.close}
            />
            <Sell
                visible={sellIsVisible}
                paymentMethod={paymentMethod}
                isNewClient={false}
                membership={{ id: membershipForSell?.id, price: membershipForSell?.price }}
                client={{ id: data?.client?.id }}
                onClose={closeSell}
                onSuccess={handleOnSuccessSell}
            />
            <EditMembership
                visible={openEditMembershipVisible}
                onClose={closeEditMembership}
                onOk={handleOnEditMembership}
            />
            <SearchClient
                visible={isSearchClientVisible}
                close={searchClient.close}
                userPicked={searchClient.userPicked}
            />
            <TakePicture
                visible={cameraIsOpen}
                close={camera.close}
                pictureTaken={camera.setPicture}
            />
            <div className="top">
                <div className="topLeft">
                    <form onSubmit={handleSubmit}>
                        <div className="formInput">
                            <label>Captura el Código de Cliente:</label>
                            <input
                                type="text"
                                autoFocus
                                value={code}
                                onChange={e => setCode(e.target.value)}
                            />
                        </div>
                        <button
                            type="submit"
                            ref={refSubmitButton}
                            style={{ display: 'none' }}
                        >submit</button>
                    </form>
                </div>
                <div className="topRight">
                    <label>{user?.firstName} {user?.lastName}</label>
                    <label>Turno: [{shift?.id}] {shift?.Gym?.name} - {shift?.Gym?.branch}</label>
                    <Clock />
                </div>
            </div>
            <div className="middle">
                <div className="left">
                    <table className="client">
                        <tbody>
                            <tr>
                                <td>Código:</td>
                                <td>{data?.client?.code}</td>
                            </tr>
                            <tr>
                                <td>Nombre:</td>
                                <td>{data?.client?.fullName}</td>
                            </tr>
                            <tr>
                                <td>Teléfono:</td>
                                <td>{data?.client?.phoneNumber}</td>
                            </tr>
                            <tr>
                                <td>Correo electrónico:</td>
                                <td>{data?.client?.email}</td>
                            </tr>
                            <tr>
                                <td>Código membresía:</td>
                                <td>
                                    <span style={{ textDecoration: newMembership && 'line-through' }}>{data?.membership?.code}</span>
                                    {newMembership && (<span>→{newMembership?.code}</span>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Membresía</td>
                                <td>
                                    <span style={{ textDecoration: newMembership && 'line-through' }}>{data?.membership?.name}</span>
                                    {newMembership && (<span>→{newMembership?.name}</span>)}
                                </td>
                            </tr>
                            <tr>
                                <td>Fecha ultimo pago:</td>
                                <td>{data?.membership?.sellDate}</td>
                            </tr>
                            <tr>
                                <td>Fecha de vencimiento:</td>
                                <td>{data?.membership?.expDate}</td>
                            </tr>
                            <tr>
                                <td>Estatus de la membresía:</td>
                                <td><span style={{ fontWeight: 'bold', color: data?.membership?.color ?? 'black' }}>{data?.membership?.statusLabel}</span></td>
                            </tr>
                            <tr>
                                <td>Por cobrar:</td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        <span style={{ textDecoration: newMembership && 'line-through' }}>{formatPrice(data?.membership?.price)}</span>
                                        {newMembership && (<span>→{formatPrice(newMembership?.price)}</span>)}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="right">
                    <div className="imgContainer">
                        <img alt="" src={newImage ?? data?.client?.image} className="clientImage" />
                        <button className="buttonSm" style={{ width: '256px' }} disabled={!data?.client} onClick={camera.open}>Tomar Fotografia</button>
                    </div>
                    <div className="commentsContainer">
                        <TextArea disabled={!data?.client} rows={6} value={comments} onChange={(e) => setComments(e.target.value)} />
                        <button className="buttonSm" disabled={!data?.client} onClick={attemptSaveComments}>Guardar comentarios</button>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <button type="button" className="button mr-12" onClick={() => openSell('cash')}>Efectivo</button>
                    <button type="button" className="button mr-12" onClick={() => openSell('tc')}>T.C.</button>
                    <button type="button" className="button mr-12" onClick={openEditMembership}>
                        {newMembership ? 'Restablecer Membresía' : 'Cambiar Membresía'}
                    </button>
                    <button type="button" className="button mr-12" onClick={() => goToScreen('createUser')}>Nuevo Cliente</button>
                    <button type="button" className="button mr-12" onClick={searchClient.open}>Buscar Cliente</button>
                </div>
                {user.userTypeId === 4 && (
                    <div className="right">
                        <button type="button" className="button" onClick={handleOnSignout}>Salir</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Menu;