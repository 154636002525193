const NotFoundScreen = () => {

    return (
        <div>
            <label>NOT FOUND</label>
        </div>
    )

}

export default NotFoundScreen;