import './header.less';
import { Layout, Avatar, Menu, Dropdown } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { signOutUser } from '../../reducers/auth';

const { Header } = Layout;

const HeaderC = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    let acronym = user?.firstName?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');

    const menu = (
        <Menu
            selectedKeys={[]}
            onClick={({ item, key, keyPath }) => {
                if (key === 'signOut') {
                    dispatch(signOutUser());
                }
            }}
        >
            {/* <Menu.Item key="userCenter">
                <Icon type="user" />Account
            </Menu.Item> */}
            {/* <Menu.Divider /> */}
            <Menu.Item key="signOut">
                <LogoutOutlined />&nbsp;Cerrar Sesión
            </Menu.Item>
        </Menu>
    );

    return (
        <Header
            style={{
                backgroundColor: '#fafafa',
                padding: '0',
                boxShadow: '0 1px 4px rgba(0, 21, 41, 0.08)',
                position: 'relative'
            }}
            className="header"
        >
            <div className="right">
                <Dropdown overlay={menu}>
                    <span className="action account">
                        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', margin: '0 6px' }}>{acronym || ''}</Avatar>
                        <span className="name">{user?.firstName || ''}</span>
                    </span>
                </Dropdown>
                <span className="action"></span>
            </div>
        </Header>
    )
}

export default HeaderC;