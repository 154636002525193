import './App.less';

import 'moment/locale/es-mx';
import moment from 'moment-timezone';

import esES from 'antd/lib/locale/es_ES';
import { ConfigProvider } from 'antd';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import initializeStore from './store.js';

import AuthRoute from './routes/auth';
import AppRoute from './routes/app';
import PublicRoute from './routes/public';

moment.tz.setDefault("America/Tijuana");

const { store, persistor } = initializeStore();

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConfigProvider locale={esES}>
					<Router>
						<Switch>
							<Route path="/auth">
								<AuthRoute />
							</Route>
							<Route path="/app">
								<AppRoute />
							</Route>
							<Route path="/public">
								<PublicRoute />
							</Route>
							<Route path="*">
								<Redirect to="/auth" />
							</Route>
						</Switch>
					</Router>
				</ConfigProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
