import API from '../api';

export const createSell = (token, values) => {
    return API(token).post('/sells/create', values);
}

export const userStatus = (token, values) => {
    return API(token).post('/sells/userStatus', values);
}

export const cancelSell = (token, values) => {
    return API(token).post('/sells/cancel', values);
}

export const getRcpt = (id) => {
    return API().post('/sells/getRcpt', { sell: { id } });
}