import { useState } from 'react';
import { Table, Tag, Button } from 'antd';
import * as UTILS from '../../lib/utils/index';
import EditCurrentMembership from './../currentMembership';

const ClientMemberships = ({ data: memberships, callbackFunc }) => {

    const [currentMembership, setCurrentMembership] = useState(null);

    return (
        <>
            <EditCurrentMembership
                membership={currentMembership}
                callbackFunc={(newValues) => {
                    setCurrentMembership(null);
                    if (newValues && typeof callbackFunc === 'function') callbackFunc(newValues);
                }}
            />
            <Table
                dataSource={memberships.sort((a, b) => b.id - a.id)}
                rowKey={'id'}
                columns={[
                    {
                        title: 'Folio Interno',
                        dataIndex: 'id',
                        key: 'id'
                    },
                    {
                        title: 'Código membresía',
                        dataIndex: '',
                        key: '',
                        render: (record) => record?.Membership?.code
                    },
                    {
                        title: 'Membresía',
                        dataIndex: '',
                        key: '',
                        render: (record) => record?.Membership?.name
                    },
                    {
                        title: 'Gimnasio',
                        dataIndex: '',
                        key: '',
                        render: (record) => `${record?.Sell?.Gym?.name} - ${record?.Sell?.Gym?.branch}`
                    },
                    {
                        title: 'Estatus',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status) => {
                            if (status === 'expired')
                                return <Tag color="orange">Expirada</Tag>
                            if (status === 'active')
                                return <Tag color="green">Activa</Tag>
                            if (status === 'canceled')
                                return <Tag color="red">Cancelada</Tag>
                        }
                    },
                    {
                        title: 'Termina',
                        dataIndex: 'endAt',
                        key: 'endAt',
                        render: (date) => {
                            const endAt = new Date(date);
                            endAt.setTime(endAt.getTime() + endAt.getTimezoneOffset() * 60 * 1000);
                            return UTILS.dateToString(endAt);
                        }
                    },
                    {
                        title: 'Controles',
                        dataIndex: 'controls',
                        key: 'controls',
                        align: 'right',
                        render: (_, record) => {
                            if (
                                record.stripeSubscriptionStatus === "active" ||
                                record.status !== "active"
                            ) {
                                return '';
                            }
                            return <Button onClick={() => setCurrentMembership(record)}>Editar</Button>;
                        }
                    }
                ]}
            ></Table>
        </>
    )
};

export default ClientMemberships;