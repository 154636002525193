import API from '../api';

export const create = (token, payload) => {
    return API(token).post('/classes/create', payload);
}

export const list = (token, payload) => {
    return API(token).post('/classes/list', payload);
}

export const update = (token, payload) => {
    return API(token).post('/classes/update', payload);
}

export const getByMonth = (token, payload) => {
    return API(token).post('/classes/getByMonth', payload);
}

export const getByIdAndDate = (token, payload) => {
    return API(token).post('/classes/getByIdAndDate', payload);
}