import { Layout, Menu } from "antd";
import { useState, useEffect } from 'react';
import * as Icons from '@ant-design/icons';

import {
    useRouteMatch,
    useHistory
} from "react-router-dom"

import { useSelector } from 'react-redux'

import getMenu from './router.js';

import ImgLogo from './../../img/Olympia_Logo-05.svg';

const { Sider } = Layout;

const Logo = ({ collapsed }) => (
    <div
        style={{
            width: collapsed ? '62%' : '100%',
            height: '64px',
            backgroundColor: '#fafafa',
            margin: collapsed ? '0 auto' : '',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <img alt="" src={ImgLogo} style={{ width: '43px' }} />
    </div>
);

const OlympiaMenu = () => {

    const { url } = useRouteMatch();
    const [current, setCurrent] = useState('');
    const history = useHistory();
    const { user } = useSelector(({ auth }) => auth);
    const menuItems = getMenu(user?.userType?.aka);

    useEffect(() => {

        for (let i = 0; i < menuItems.length; i++) {
            const menu = menuItems[i];
            if (menu.url) {
                if (window.location.href.includes(menu.url)) {
                    setCurrent(menu.key);
                }
            } else {
                for (let j = 0; j < menu.items.length; j++) {
                    const element = menu.items[j];
                    if (window.location.href.includes(element.url)) {
                        setCurrent(element.key);
                    }
                }
            }
        }

    }, []);

    const renderMenuItems = (menuItems) => {
        const items = [];

        for (const menu of menuItems) {
            const Icon = Icons[menu.icon];
            if (menu.items) {
                items.push(
                    <Menu.Divider dashed={true} key={menu.key} />,
                    <Menu.ItemGroup style={{ textAlign: 'center' }} title={menu.label} key={menu.key + '1'} />,
                    ...renderMenuItems(menu.items)
                );
            } else {
                let URL;
                if (menu.root) URL = '/' + menu.url;
                else URL = `${url.replace(/\/$/, '')}${menu.url === '/' ? '' : '/' + menu.url}`
                items.push(
                    <Menu.Item
                        key={menu.key}
                        onClick={() => history.push(`${URL}`)}
                    >
                        <Icon />
                        &nbsp;
                        <span className="nav-text">{menu.label}</span>
                    </Menu.Item>
                );
            }
        }

        return items;
    }

    return (
        <Sider
            style={{
                backgroundColor: '#fafafa',
                boxShadow: '-2px -1px 16px rgba(0, 21, 41, 0.35)',
                position: 'relative',
                zIndex: 10
            }}
            collapsed={true}
            collapsedWidth={80}
        >
            <Logo collapsed={false} />
            <Menu
                style={{ backgroundColor: '#fafafa', borderRight: 'none' }}
                mode="inline"
                onClick={(e) => setCurrent(e.key)}
                selectedKeys={[current]}
            >
                {renderMenuItems(menuItems)}
            </Menu>
        </Sider>
    );
};

export default OlympiaMenu;