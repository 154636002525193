import './index.less';

import { Modal, Row, Col } from 'antd';
import { formatPrice, dateToString } from '../../lib/utils';

const ModalC = ({ visible, closeModal, record = {}, reloadTable }) => {

    const onCancel = () => {
        closeModal();
    }

    let totalInDesk = parseFloat(record.cashAtStart) + parseFloat(record.cash);
    let totalSell = parseFloat(record.cash) + parseFloat(record.tc);
    let offset = parseFloat(record.cash) - parseFloat(record.cashAtEnd);

    return (
        <Modal
            visible={visible}
            title="Turno"
            okText="Continuar"
            cancelText="Cancelar"
            footer={null}
            onCancel={onCancel}
        >
            <Row gutter={[24, 6]}>
                <Col span={24}>
                    <span className="bold">Folio:&nbsp;</span>
                    <span>{record.id}</span>
                </Col>
                <Col span={24}>
                    <span className="bold">Gimnasio:&nbsp;</span>
                    <span>{record.Gym?.name} - {record.Gym?.branch}</span>
                </Col>
                <Col span={24}>
                    <span className="bold">Fecha de Inicio:&nbsp;</span>
                    <span>{dateToString(new Date(record.startAt), true)}</span>
                </Col>
                <Col span={24}>
                    <span className="bold">Fecha de Terminicación:&nbsp;</span>
                    <span>
                        {
                            !!record.endAt ?
                                (<>{dateToString(new Date(record.endAt), true)}</>) :
                                (<>-</>)
                        }
                    </span>
                </Col>
                <Col span={24}>
                    <span className="bold">Usuario:&nbsp;</span>
                    <span>{record.User?.firstName} {record.User?.lastName}</span>
                </Col>
                <table className="tableItems">
                    <tbody>
                        <tr className="borderTop">
                            <td>DINERO EN CAJA</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Fondo de Caja</td>
                            <td align="right">{formatPrice(record.cashAtStart)}</td>
                        </tr>
                        <tr>
                            <td>Ventas +</td>
                            <td align="right">{formatPrice(record.cash)}</td>
                        </tr>
                        <tr>
                            <td>Total en Caja</td>
                            <td align="right">{formatPrice(totalInDesk)}</td>
                        </tr>
                        <tr className="borderTop">
                            <td>VENTAS</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Efectivo</td>
                            <td align="right">{formatPrice(record.cash)}</td>
                        </tr>
                        <tr>
                            <td>Terminal Bancaria</td>
                            <td align="right">{formatPrice(record.tc)}</td>
                        </tr>
                        <tr>
                            <td>Total de Ventas</td>
                            <td align="right">{formatPrice(totalSell)}</td>
                        </tr>
                        <tr className="borderTop">
                            <td>DECLARADO</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Efectivo</td>
                            <td align="right">{formatPrice(record.cashAtEnd)}</td>
                        </tr>
                        <tr>
                            <td>Diferencia</td>
                            <td align="right">{formatPrice(offset)}</td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        </Modal>
    );
}

export default ModalC;