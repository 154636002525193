import { Button, Avatar, Space } from "antd";
import { UserOutlined } from '@ant-design/icons'
import AdminPage from "../../components/adminPage";

import {
    useHistory,
    useRouteMatch
} from "react-router-dom";

const ClientsScreen = () => {

    const { url } = useRouteMatch();
    const history = useHistory();
    const endpoint = '/clients/list';

    const renderAvatar = (_, user) => {
        return (
            <Avatar size={64} src={user.image} icon={<UserOutlined />} />
        );
    }

    const renderName = (_, user) => {
        return `${user.firstName} ${user.lastName}`;
    }

    const renderControls = (_, record) => {
        return <Button onClick={() => {
            history.push(`${url}/${record.code}`);
        }}>Ver</Button>
    }

    const columns = [
        { key: 'code', title: '', render: renderAvatar },
        { key: 'code', title: 'Código', },
        { key: 'name', title: 'Nombre', render: renderName },
        { key: 'phoneNumber', title: 'Teléfono' },
        { key: 'email', title: 'Correo electrónico' },
        { key: 'controls', title: 'Controles', align: 'right', render: renderControls }
    ];

    const filters = [
        { title: 'Código', key: 'code' },
        { title: 'Nombre', key: 'fullName' },
        { title: 'Teléfono', key: 'phoneNumber' },
        { title: 'Correo electrónico', key: 'email' },
    ];

    return (
        <AdminPage
            pageTitle="Clientes"
            columns={columns}
            endpoint={endpoint}
            filters={filters}
            showAddButton={false}
        />
    )
}

export default ClientsScreen;