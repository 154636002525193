const Screens = {
    'dashboard': require('./dashboard').default,
    'pos': require('./pos').default,
    'clases': require('./agenda').default,
    'kiosko': require('./kiosko').default,
    'clients': require('./clients').default,
    'sells': require('./sells').default,
    'admin/users': require('./admin/users').default,
    'admin/memberships': require('./admin/memberships').default,
    'admin/gyms': require('./admin/gyms').default,
    'admin/products': require('./admin/products').default,
    'dailyCashClosing': require('./dailyCashClosing').default,
    'access-control': require('./accessControl').default,
};

export default Screens;