import { message } from 'antd';

export const createColumn = (dateIndex, title) => {
    return {
        key: dateIndex,
        dateIndex,
        title
    }
};

export const compareObjects = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const showMessageError = (error = 'Lo sentimos, tenemos problemas temporales con nuestros servicios.') => {
    message.error(error);
}

export const formatPrice = (value) => {

    if (isNaN(value) || value === null || value === undefined) return '--';

    let price;
    let floatValue;
    if (typeof value === 'string' && !value.length) price = '0';
    else if (typeof value === 'undefined' || typeof value === 'object') price = '0';
    else price = value;

    floatValue = parseFloat(price);

    return (floatValue).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export const daysForExpiration = (b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const a = new Date();
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const dateToString = (date, withTime = false) => {

    let dd;

    if (typeof dd !== 'object') {
        dd = new Date(date);
    } else {
        dd = date;
    }

    const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];
    const day = dd.getDate();
    const month = dd.getMonth();
    const year = dd.getFullYear();

    let strTime = '';
    if (withTime) {
        let time = dd.toLocaleString().split(', ')[1];
        if (time) strTime += ', ' + time;
    }

    return `${day} de ${months[month]} del ${year}${strTime}`;
};

export const getPaymentMethodName = (key) => {
    const map = {
        'cash': 'Efectivo',
        'tc': 'Terminal Bancaria'
    }
    return map[key] ?? key;
}

export const extractIVA = (amount, iva) => {
    return (amount * iva) / 100;
}

export const substractIVA = (amount, iva) => {
    return amount - extractIVA(amount, iva);
}

export const formatInputPhoneNumber = (original) => {

    // Strip all characters from the input except digits
    let value = original.replace(/\D/g, '');

    // Trim the remaining input to ten characters, to preserve phone number format
    value = value.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    let size = value.length;

    if (size === 0) {
        return value
    }
    else if (size >= 1 && size < 4) {
        return '(' + value;
    }
    else if (size >= 4 && size <= 6) {
        return '(' + value.substring(0, 3) + ')' + value.substring(3, 6);
    }
    else {
        return '(' + value.substring(0, 3) + ')' + value.substring(3, 6) + '-' + value.substring(6, 10);
    }

}

export const str24hrsTo12hrs = (str) => {

    if (!str) return '--';

    var parts = str.split(":");

    // hours
    let hours = parseInt(parts[0]);
    let suffix = 'am';

    if (hours > 12) {
        suffix = 'pm';
        hours -= 12;
    }

    return `${hours}:${parts[1]} ${suffix}`;
};