import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';

import Config from '../../conf';

import * as GYM from '../../lib/service/gym';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const uploadButton = (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
)

const Gallery = ({ gymId }) => {

    const { accessToken } = useSelector(state => state.auth);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);

    useEffect(() => { getData(); }, []);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || (file.preview));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleCancel = () => setPreviewOpen(false);

    const handleRemove = (file) => {
        const id = file.id ?? file.response.id;
        return GYM.deleteImageGallery(accessToken, id);
    };

    const getData = () => {
        GYM.getImagesGallery(accessToken, gymId)
            .then(response => {
                if (response.status === 200) {
                    setFileList(response.data.data);
                }
            })
            .catch(err => null)
            .finally(() => null);
    };

    return (
        <div className='test'>
            <Upload
                action={`${Config.API_URL}/gyms/uploadImageGallery/${gymId}`}
                headers={{ 'Authorization': 'Bearer ' + accessToken }}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemove}
            >
                {uploadButton}
            </Upload>
            <div className='tes2'>
                <Modal visible={previewOpen} title={previewTitle} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        </div >
    );
};

export default Gallery;