import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";

import * as ClassesServices from './../../lib/service/classes';

const ProfileScreen = (props) => {

    const { accessToken } = useSelector(state => state.auth);
    const { classId, date } = useParams();
    const [data, setData] = useState([]);
    const [clazz, setClazz] = useState([]);

    useEffect(() => {

        ClassesServices.getByIdAndDate(accessToken, {
            classId: parseInt(classId),
            date
        }).then((response) => {
            const { data, status } = response;
            if (status === 200) {
                setData(data.data.clients);
                setClazz(data.data.clazz);
            }
        });

    }, []);

    return (
        <PageWrapper title={`${clazz?.service} - ${clazz?.level} - ${date}`}>
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <Card title="Informacion">
                        <Row gutter={[24, 24]}>
                            <Col span={12}><b>Categoria</b></Col><Col span={12}>{clazz?.service}</Col>
                            <Col span={12}><b>Nivel</b></Col><Col span={12}>{clazz?.level}</Col>
                            <Col span={12}><b>Gimnasio</b></Col><Col span={12}>{clazz?.gymName}</Col>
                            <Col span={12}><b>Instructor</b></Col><Col span={12}>{clazz?.trainerName}</Col>
                            <Col span={12}><b>Hora de Inicio</b></Col><Col span={12}>{clazz?.startTime}</Col>
                            <Col span={12}><b>Hora de Termino</b></Col><Col span={12}>{clazz?.endTime}</Col>
                            <Col span={12}><b>Precio</b></Col><Col span={12}>{clazz?.price}</Col>
                            {/* <Col span={24}>
                                <Button block={true}>Editar</Button>
                            </Col> */}
                        </Row>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card>
                        <Table
                            columns={[
                                {
                                    dataIndex: 'code',
                                    title: 'Codigo',
                                },
                                {
                                    dataIndex: 'fullName',
                                    title: 'Cliente',
                                },
                                {
                                    dataIndex: 'phoneNumber',
                                    title: 'Telefono',
                                },
                                {
                                    dataIndex: 'email',
                                    title: 'Correo Electronico',
                                }
                            ]}
                            rowKey={(record) => record.id}
                            dataSource={data}
                        />
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    );

};

export default ProfileScreen;