const ENV = 'prod';

const CONFIGS = {
    'local': {
        ENV,
        API_URL: 'http://localhost:3000'
    },
    'dev': {
        ENV,
        API_URL: 'https://api.dev.olympiagms.com'
    },
    'prod': {
        ENV,
        API_URL: 'https://api.olympiagms.com'
    }
};

export default CONFIGS[ENV];