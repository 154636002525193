import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment';

import { Row, Col, Card, Button } from 'antd';

import * as ClassesServices from './../../lib/service/classes';

import { useSelector } from 'react-redux';

import PageWrapper from "../../components/pageWrapper";
import { useCallback, useRef, useEffect, useState } from 'react';
import ClassModal from './../../components/classModal';

import {
    useHistory,
    useRouteMatch
} from "react-router-dom";

import * as Utils from '../../lib/utils';

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const defaultTZ = moment.tz.guess()
moment.tz.setDefault(defaultTZ)
const localizer = momentLocalizer(moment) // or globalizeLocalizer

function setTime(date, time) {
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
}

function setDate(date, _date) {
    const [year, month, day] = _date.split("-");
    date.setFullYear(parseInt(year));
    date.setMonth(parseInt(month) - 1);
    date.setDate(parseInt(day));
    return date;
}

const Agenda = () => {

    const [events, setEvents] = useState([]);
    const { accessToken } = useSelector(state => state.auth);
    const classModalRef = useRef();

    const { url } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        getClases(
            {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
            }
        );
    };

    const getClases = (payload) => {
        ClassesServices.getByMonth(accessToken, payload).then(response => {
            const newEvents = response.data.data.classes.map(event => {

                let startD = new Date();
                startD = setTime(startD, event.start.time);
                startD = setDate(startD, event.start.date);

                let endD = new Date();
                endD = setTime(endD, event.end.time);
                endD = setDate(endD, event.end.date);

                return {
                    ...event,
                    start: startD,
                    end: endD
                }
            });
            setEvents(newEvents);
        })
            .catch(err => console.log(err));
    }

    const handleSelectEvent = useCallback(
        (event) => {
            history.push(`${url}/${event.id}/${event.itemClass.date}`);
        },
        []
    );

    const handleOnNavigate = (newDate) => {
        getClases(
            {
                month: newDate.getMonth() + 1,
                year: newDate.getFullYear(),
            }
        );
    }

    const onCreateCallback = (values) => {
        initialData();
    }

    return (
        <PageWrapper title="Clases">
            <Row gutter={[24, 24]}>
                <ClassModal ref={classModalRef} onCreateCallback={onCreateCallback} />
                <Col span={24}>
                    <Card>
                        <Row gutter={[24, 24]}>
                            <Col span={4}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        classModalRef?.current?.open()
                                    }}
                                >Crear Clase</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>
                        <div style={{ height: '700px', }}>
                            <Calendar
                                localizer={localizer}
                                events={events}
                                components={{
                                    week: {
                                        event: Event
                                    },
                                    day: {
                                        event: Event
                                    },
                                    event: Event
                                }}
                                step={60}
                                defaultView={Views.WEEK}
                                views={[Views.MONTH, Views.WEEK, Views.DAY]}
                                showMultiDayTimes={true}
                                selectable={true}
                                dayLayoutAlgorithm="no-overlap"
                                onNavigate={handleOnNavigate}
                                onSelectEvent={handleSelectEvent}
                                formats={{
                                    timeGutterFormat: (date, culture, localizer) => {
                                        return moment(date).format('hh:mm A');
                                    }
                                }}
                                culture={"es"}
                                messages={{
                                    week: 'Semana',
                                    work_week: 'Semana de trabajo',
                                    day: 'Día',
                                    month: 'Mes',
                                    previous: 'Atrás',
                                    next: 'Siguiente',
                                    today: 'Hoy',
                                    agenda: 'El Diario',
                                    showMore: (total) => `+${total} más`,
                                }}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    )
};

function Event({ event }) {

    const LEVEL_ENUMS = {
        'beginner': 'Principiante',
        'intermediate': 'Intermedio',
        'advanced': 'Avanzado'
    };

    return (
        <span>
            <strong>{event.title}</strong>
            <label> - {LEVEL_ENUMS[event.itemClass.level]}</label>
            <label> - {Utils.str24hrsTo12hrs(event.itemClass.startTime)} - {Utils.str24hrsTo12hrs(event.itemClass.endTime)}</label>
        </span>
    )
}

export default Agenda;