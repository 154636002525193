import React, { useImperativeHandle, useState, forwardRef } from "react";
import { useSelector } from 'react-redux'
import { Modal, Spin, Table, message, Tag, Button, Row, Col } from 'antd';

import {
    CreditCardOutlined,
    DollarOutlined
} from '@ant-design/icons';

import API from '../../lib/api';
import { formatPrice } from "../../lib/utils";

const ENUM_INTERVAL = {
    'day': 'Día',
    'month': 'Mes',
    'week': 'Semana',
};

const MembershipsModal = (props, ref) => {

    const [visible, setVisible] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const { accessToken, shift } = useSelector(state => state.auth);

    const onOkHandle = () => {

    }

    const onCancelHandle = () => {
        setVisible(false)
    }

    const open = () => {
        setVisible(true);
        loadMemberships();
    }

    const loadMemberships = () => {
        const pagination = { pageSize: 100 };
        const filters = { gymId: shift.gymId, status: 'active' };
        setFetching(true);
        API(accessToken).post('memberships/list', { pagination, filters })
            .then(({ status, data }) => {
                if (status === 200) {
                    setMemberships(data.data);
                }
            })
            .catch(err => null)
            .finally(_ => setFetching(false));
    }

    const selectMembership = (membership, paymentMethod) => {
        props.onSelect?.(membership, paymentMethod);
        setVisible(false);
    }

    useImperativeHandle(ref, () => {
        return {
            open,
        }
    });

    return (
        <Modal
            title={"Adquirir Membresía"}
            visible={visible}
            onOk={onOkHandle}
            onCancel={onCancelHandle}
            footer={false}
        >
            <Spin spinning={fetching}>
                <Table
                    pagination={false}
                    dataSource={memberships}
                    rowKey={r => r.id}
                    columns={[
                        {
                            title: 'Nombre',
                            dataIndex: 'name',
                            key: 'name'
                        },
                        {
                            title: 'Precio',
                            dataIndex: 'price',
                            key: 'price',
                            render: (val) => formatPrice(val)
                        },
                        {
                            title: 'Duración',
                            dataIndex: 'interval',
                            key: 'interval',
                            render: (_, record) => {
                                return (<label>{record.quantity} - {ENUM_INTERVAL[record.type] ?? record.type}</label>)
                            }
                        },
                        {
                            title: 'Pago',
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (_, record) => {
                                return (
                                    <Row>
                                        <Col span={12}>
                                            <Button
                                                block size='middle'
                                                icon={<CreditCardOutlined />}
                                                style={{ backgroundColor: '#faac1d', color: '#fff' }}
                                                onClick={() => selectMembership(record, 'tc')}
                                            ></Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                block size='middle'
                                                icon={<DollarOutlined />}
                                                style={{ backgroundColor: '#4dba4d', color: '#fff' }}
                                                onClick={() => selectMembership(record, 'cash')}
                                            ></Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        }
                    ]}
                />
            </Spin>
        </Modal>
    )
};

export default forwardRef(MembershipsModal);