import { useEffect, useState } from 'react';
import { Modal, Select, InputNumber, Spin, message, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setShift } from '../../reducers/auth';
import { openShift } from '../../lib/service/shift';
import { showMessageError } from '../../lib/utils'

const { Option } = Select;

const OpenShift = () => {

    const { user, accessToken, shift } = useSelector(state => state.auth);
    const [gym, setGym] = useState(undefined);
    const [cash, setCash] = useState(0);
    const [visible, setVisible] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (shift) {
            setGym(undefined);
            setCash(0);
        }
    }, [shift]);

    const closeModal = () => {
        setVisible(false);
    }

    const openModal = () => {
        setVisible(true);
    }

    const handleChangeGym = (gymId) => {
        setGym(gymId);
    }

    const handleChangeCash = (value) => {
        setCash(value);
    }

    const startShift = async () => {
        if (!gym || cash < 0 || cash === null || cash === undefined) {
            alert('POR FAVOR COMPLETE TODOS LOS CAMPOS');
            return;
        }
        try {
            setSpinning(true);
            const { status, data } = await openShift(accessToken, { gymId: gym, cashAtStart: cash });
            setSpinning(false);
            if (status !== 200) {
                showMessageError();
                return;
            }
            message.success("Has iniciado turno correctamente.");
            dispatch(setShift(data.data.shift));
        } catch (error) {
            setSpinning(false);
        }
    }

    return (
        <span>
            <Button type="primary" onClick={openModal}>Iniciar Turno</Button>
            <Modal
                footer={null}
                visible={visible}
                onCancel={closeModal}
            >
                <Spin spinning={spinning}>
                    <div>
                        <h2>INICIAR TURNO</h2>
                        <h2>Por favor selecciona un Gimnasio para continuar</h2>
                        <Select
                            size='large'
                            value={gym}
                            onChange={handleChangeGym}
                            style={{ width: '100%' }}
                        >
                            {user?.gyms?.map((item, i) => {
                                return (<Option key={i.toString()} value={item.id}>{item.name} - {item.branch}</Option>)
                            })}
                        </Select>
                        <br /><br />
                        <h2>Dinero en Cajon (Fondo de Caja)</h2>
                        <InputNumber
                            size="large"
                            value={cash}
                            onChange={handleChangeCash}
                            style={{ width: '100%' }}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}

                        />
                        <Button
                            onClick={startShift}
                            block type="primary" size="large"
                            style={{ marginTop: '12px' }}
                        >Iniciar Turno</Button>
                    </div>
                </Spin>
            </Modal>
        </span>
    );
};

export default OpenShift;