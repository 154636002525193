import { useSelector } from 'react-redux';
import { useState, useRef } from 'react';

import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Avatar,
    Tag
} from 'antd';

import {
    CreditCardOutlined,
    DollarOutlined,
    DeleteOutlined,
    UserOutlined
} from '@ant-design/icons';

import OpenShift from './openShift';
import CloseShift from './closeShift';
import Clock from './clock';
import MembershipsModal from './memberships.modal';

import Sell from './sell';
import ClientModal from './../../components/clientModal';

import { formatPrice } from '../../lib/utils';
import { getByCodeFrontDesk } from '../../lib/service/clients';

const POS = () => {

    const { accessToken, shift } = useSelector(state => state.auth);
    const { memberships } = useSelector(state => state.pos);
    const [items, setItems] = useState([]);
    const [clientId, setClientId] = useState(undefined);
    const [codeItem, setCodeItem] = useState('');
    const [codeClient, setCodeClient] = useState('');
    const [currentClient, setCurrentClient] = useState(null);
    const [sellVisible, setSellVisible] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');

    const membershipModalRef = useRef(0);

    const addItem = async (newItem) => {
        const newData = [...items];
        const findIndex = newData.findIndex(item => item.id === newItem.id);

        if (findIndex !== -1 && newItem.type === "membership") {
            return;
        }
        else if (findIndex !== -1) {
            newData[findIndex].quantity += newItem.quantity;
        }
        else {
            newData.push(newItem);
        }

        setItems(newData);
    };

    const deleteItem = async (_item) => {
        const newData = [...items];
        const findIndex = newData.findIndex(item => item.id === _item.id);

        if (findIndex !== -1) {
            newData.splice(findIndex, 1)
        }

        setItems(newData);
    };

    const renderPrice = (_, record, index) => {
        return formatPrice(record.price);
    }

    const renderSubtotal = (_, record, index) => {
        return formatPrice(record.price * record.quantity);
    }

    const renderActions = (_, record, index) => {
        return <Button
            size="small"
            onClick={() => deleteItem(record)}
            type="primary"
            danger shape="circle"
            icon={<DeleteOutlined />}
        />
    }

    const calcTotal = () => {
        return items.reduce(function (acc, item) {
            return acc += item.quantity * item.price;
        }, 0);
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            let _codeItem = codeItem.replace(/ /g, '');
            setCodeItem('');

            const findIndex = memberships.findIndex(membership => membership.code === _codeItem);
            if (findIndex !== -1) {
                const newItem = {
                    type: 'membership',
                    id: memberships[findIndex].id,
                    price: memberships[findIndex].price,
                    quantity: 1,
                    description: memberships[findIndex].name
                };
                addItem(newItem);
            }
        }
    }

    const _handleKeyDownForClient = async (e) => {
        if (e.key === 'Enter') {
            let _codeClient = codeClient.replace(/ /g, '');
            setCodeClient('');
            getByCodeFrontDesk(accessToken, { code: _codeClient })
                .then(response => setCurrentClient(response.data.data ?? null));
        }
    }

    const pay = (_paymentMethod) => {
        setPaymentMethod(_paymentMethod);
        setSellVisible(true);
    }

    const sellProps = {
        visible: sellVisible,
        items,
        paymentMethod,
        clientId,
        total: calcTotal(),
        onClose: () => {
            setSellVisible(false);
        },
        onSuccess: () => {
            setItems([]);
            setClientId(undefined);
            if (currentClient) {
                getByCodeFrontDesk(accessToken, { code: currentClient.code })
                    .then(response => setCurrentClient(response.data.data ?? null));
            }
        },
    };

    const handleOnCreateUser = (newUser) => {
        setCodeClient('');
        getByCodeFrontDesk(accessToken, { code: newUser.code })
            .then(response => setCurrentClient(response.data.data ?? null));
    };

    const addMembership = () => {
        membershipModalRef.current.open();
    }

    const sellMembership = (_membership, _paymentMethod = "cash") => {
        setPaymentMethod(_paymentMethod);
        setClientId(currentClient.id);
        setItems([{
            type: 'membership',
            quantity: 1,
            id: _membership.id,
            price: _membership.price,
            description: _membership.name
        }]);
        setSellVisible(true);
    }

    return (
        <div className="pos">
            <Sell {...sellProps} />
            <MembershipsModal ref={membershipModalRef} onSelect={sellMembership} />
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <Card>
                        Turno: {shift?.id ?? '-'}
                        &nbsp;{`<>`}&nbsp;
                        Gimnasio: {shift?.Gym?.name ?? '-'} - {shift?.Gym?.branch ?? '-'}
                        &nbsp;{`<>`}&nbsp;
                        <Clock />
                        &nbsp;{`<>`}&nbsp;
                        {shift ? <CloseShift /> : <OpenShift />}
                    </Card>
                </Col>
                {shift?.id && (
                    <Col span={24}>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Card>
                                            <Row gutter={[12, 12]}>
                                                <Col span={24}>
                                                    <div style={{ display: 'inline-block', width: '70%' }}>
                                                        <input
                                                            placeholder='Buscar cliente por código, nombre o apellido'
                                                            autoFocus={true}
                                                            style={{
                                                                width: '100%',
                                                                padding: '8px 6px',
                                                            }}
                                                            value={codeClient}
                                                            onChange={e => setCodeClient(e.target.value)}
                                                            onKeyDown={_handleKeyDownForClient}
                                                        />
                                                    </div>
                                                    <div style={{ display: 'inline-block', width: '20%', margin: '0 2%' }}>
                                                        <ClientModal onCreateUser={handleOnCreateUser} gymId={shift?.gymId} />
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <br />
                                                    <Row gutter={[12, 12]}>
                                                        <Col span={24}>
                                                            <Row gutter={[12, 12]}>
                                                                <Col span={4}>
                                                                    <Avatar size={94} src={currentClient?.image} icon={<UserOutlined />} />
                                                                </Col>
                                                                <Col span={10}>
                                                                    <h3>
                                                                        {currentClient?.code ?? '-'}<br />
                                                                        {currentClient?.fullName ?? '-'}<br />
                                                                        {currentClient?.email ?? '-'}<br />
                                                                        {currentClient?.phoneNumber ?? '-'}<br />
                                                                    </h3>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Button
                                                                        disabled={!Boolean(currentClient)}
                                                                        type='primary'
                                                                        block
                                                                        size='large'
                                                                        onClick={() => addMembership()}
                                                                    >ADQUIRIR MEMBRESIA</Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Table
                                                                pagination={false}
                                                                size='small'
                                                                columns={[
                                                                    { key: 'name', dataIndex: 'name', title: 'Membresía' },
                                                                    { key: 'expDate', dataIndex: 'expDate', title: 'Vencimiento' },
                                                                    {
                                                                        key: 'statusLabel',
                                                                        dataIndex: 'statusLabel',
                                                                        title: '',
                                                                        render: (val, record, index) => {
                                                                            return <Tag color={record.color}>{val}</Tag>
                                                                        }
                                                                    },
                                                                    {
                                                                        key: 'id',
                                                                        dataIndex: 'id',
                                                                        title: '',
                                                                        align: 'center',
                                                                        render: (val, record, index) => {
                                                                            return (
                                                                                <Row>
                                                                                    <Col span={12}>
                                                                                        <Button
                                                                                            block size='middle'
                                                                                            icon={<CreditCardOutlined />}
                                                                                            style={{ backgroundColor: '#faac1d', color: '#fff' }}
                                                                                            onClick={() => pay('tc')}
                                                                                        ></Button>
                                                                                    </Col>
                                                                                    <Col span={12}>
                                                                                        <Button
                                                                                            block size='middle'
                                                                                            icon={<DollarOutlined />}
                                                                                            style={{ backgroundColor: '#4dba4d', color: '#fff' }}
                                                                                            onClick={() => pay('cash')}
                                                                                        ></Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        }
                                                                    },
                                                                ]}
                                                                dataSource={currentClient?.memberships ?? []}
                                                                rowKey={record => record.id}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                            <Col span={12}>

                                <Col span={24}>
                                    <Card>
                                        <div>
                                            <input
                                                placeholder='Buscar producto por código'
                                                autoFocus={false}
                                                style={{
                                                    width: '100%',
                                                    padding: '8px 6px',
                                                    // border: 'solid 3px blue'
                                                }}
                                                value={codeItem}
                                                onChange={e => setCodeItem(e.target.value)}
                                                onKeyDown={_handleKeyDown}
                                            />
                                        </div>
                                        <div>
                                            <Table
                                                size='small'
                                                pagination={{ pageSize: 7 }}
                                                bordered={true}
                                                columns={[
                                                    { title: 'Producto', dataIndex: 'description' },
                                                    { title: 'Precio', render: renderPrice },
                                                    { title: 'Cantidad', dataIndex: 'quantity', align: 'center' },
                                                    { title: 'Subtotal', render: renderSubtotal },
                                                    { title: '', render: renderActions },
                                                ]}
                                                dataSource={items}
                                                rowKey={(row) => row.id}
                                            />
                                        </div>
                                        <div>
                                            <Row>
                                                <Col span={24}>
                                                    <div
                                                        style={{
                                                            fontFamily: 'Orbitron',
                                                            fontSize: '27px',
                                                            color: 'white',
                                                            textAlign: 'right',
                                                            background: '#302e2e',
                                                            padding: 6,
                                                        }}
                                                    >{formatPrice(calcTotal())}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col span={12}>
                                                    <Button
                                                        block size='large'
                                                        icon={<CreditCardOutlined />}
                                                        style={{
                                                            backgroundColor: '#faac1d',
                                                            color: 'white'
                                                        }}
                                                        onClick={() => pay('tc')}
                                                    >TARJETA</Button>
                                                </Col>
                                                <Col span={12}>
                                                    <Button
                                                        block size='large'
                                                        icon={<DollarOutlined />}
                                                        style={{
                                                            backgroundColor: '#4dba4d',
                                                            color: 'white'
                                                        }}
                                                        onClick={() => pay('cash')}
                                                    >EFECTIVO</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                                {/* <Card>
                                    <Tabs defaultActiveKey="1">
                                        <Tabs.TabPane tab="Membresías" key="1">
                                            <Memberships addItem={addItem} />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Productos" key="2">
                                            <Products addItem={addItem} />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </Card> */}
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default POS;