import './createUser.less';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import API from '../../lib/api';
import { formatPrice, formatInputPhoneNumber } from '../../lib/utils';

import TakePicture from './takePicture';
import Sell from './sell';

const CreateUser = ({ goToScreen }) => {

    const { accessToken } = useSelector(state => state.auth);

    const [memberships, setMemberships] = useState([]);
    const [membershipSelected, setMembershipSelected] = useState(0);
    const [searchCode, setSearchCode] = useState('');

    const [image, setImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const [sellIsVisible, setSellIsVisible] = useState(false);
    const [paymentType, setPaymentType] = useState('');

    const [cameraIsOpen, toggleCamera] = useState(false);

    const camera = {
        open: function () {
            toggleCamera(true);
        },
        close: function () {
            toggleCamera(false);
        },
        setPicture: function (imgDataUrl) {
            setImage(imgDataUrl);
        }
    }

    useEffect(() => {
        API(accessToken)
            .post('memberships/list', { pagination: { pageSize: 100 } })
            .then(({ status, data }) => {
                if (status === 200) {
                    setMemberships(data.data);
                }
            })
            .catch(err => null);
    }, []);

    const openPayment = (type) => {
        setPaymentType(type);
        setSellIsVisible(true);
    }

    const closePayment = () => {
        setSellIsVisible(false);
    }

    const onSuccess = (sell) => {
        goToScreen('menu')
    }

    const membership = memberships.find(item => item.id === membershipSelected);

    return (
        <div className="kioskoCreateUser">
            <Sell
                visible={sellIsVisible}
                membership={{ id: membership?.id, price: membership?.price }}
                paymentMethod={paymentType}
                isNewClient={true}
                client={{
                    image,
                    lastName,
                    firstName,
                    phoneNumber,
                    email
                }}
                onClose={closePayment}
                onSuccess={onSuccess}
            />
            <TakePicture
                visible={cameraIsOpen}
                close={camera.close}
                pictureTaken={camera.setPicture}
            />
            <div className="top">
                <div className="left">
                    <div className="mb-8">
                        <img alt="" src={image} />
                    </div>
                    <div className="formInput">
                        <label>Nombre(s):</label>
                        <input
                            type="text"
                            autoFocus
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="formInput">
                        <label>Apellidos(s):</label>
                        <input
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="formInput">
                        <label>Teléfono:</label>
                        <input
                            type="text"
                            placeholder="(LADA)NÚMERO"
                            value={phoneNumber}
                            onChange={(e) => {
                                let original = e.target.value;
                                setPhoneNumber(formatInputPhoneNumber(original));
                            }}
                        />
                    </div>
                    <div className="formInput">
                        <label>Correo electrónico:</label>
                        <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="right">
                    <div className="formInput">
                        <label>Buscar Membresia:</label>
                        <input
                            type="text"
                            onChange={(e) => setSearchCode(e.target.value)}
                        />
                    </div>
                    <div className="membershipList">
                        {memberships.filter(item => {
                            return item.code.includes(searchCode);
                        }).map((item, index) => {
                            return (
                                <div
                                    key={index.toString()}
                                    className={`membershipItem ${item.id === membershipSelected ? 'active' : ''}`}
                                    onClick={() => {
                                        setMembershipSelected(item.id === membershipSelected ? 0 : item.id);
                                    }}
                                >
                                    <label>{item.code}</label>
                                    <label>{item.name}</label>
                                    <label>{formatPrice(item.price)}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="left">
                    <button type="button" onClick={() => openPayment('cash')} className="button mr-12" >Efectivo</button>
                    <button type="button" onClick={() => openPayment('tc')} className="button mr-12">T.C.</button>
                    <button type="button" className="button" onClick={camera.open}>Tomar Fotografia</button>
                </div>
                <div className="right">
                    <button type="button" className="button" onClick={() => goToScreen('menu')}>Menu Principal</button>
                </div>
            </div>
        </div>
    )
}

export default CreateUser;