import API from '../api';

export const create = (token, values) => {
    return API(token).post('/clients/create', values);
}

export const getClients = (token, values) => {
    return API(token).post('/clients/list', values);
}

export const updateClient = (token, values) => {
    return API(token).post('/clients/update', values);
}

export const getByCode = (token, values) => {
    return API(token).post('/clients/getByCode', values);
}

export const getByCodeFrontDesk = (token, values) => {
    return API(token).post('/clients/getByCodeFrontDesk', values);
}

export const registerAttendance = (token, values) => {
    return API(token).post('/clients/registerAttendance', values);
}