import './index.less';

import React, { useState, useEffect } from 'react';
import { notification, Modal, Select, Button, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { SettingOutlined } from '@ant-design/icons';

import { registerAttendance } from '../../lib/service/clients';

import _audio_error from './../../media/error.mp3';
import _audio_success from './../../media/success.mp3';

import { signOutUser } from '../../reducers/auth';

const AccessControl = () => {

    const dispatch = useDispatch();
    const { accessToken, user } = useSelector(state => state.auth);
    const [code, setCode] = useState([]);
    const [gymId, setGymId] = useState(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [client, setClient] = useState(undefined);

    // useEffect(() => {
    //     let yourInputVal = document.getElementById('inputcode');
    //     yourInputVal.readOnly = true;
    //     yourInputVal.focus();
    //     setTimeout(function () {
    //         document.getElementById('inputcode').readOnly = false;
    //     });
    // }, []);

    // useEffect(() => {
    //     if (gymId === undefined) return;
    //     document.getElementById('inputcode').focus();
    //     document.getElementById('inputcode').onblur = function (event) {
    //         var blurEl = this;
    //         setTimeout(function () { blurEl?.focus() }, 10);
    //     };
    //     // document.getElementById('inputcode').style.display = "none";
    //     return () => {
    //         document.getElementById('inputcode')?.removeEventListener("blur", null);
    //     }
    // }, [gymId]);

    useEffect(() => {

        if(gymId === undefined) return;

        // Author: Neel Bhanushali <neal.bhanushali@gmail.com>
        document.addEventListener('keydown', function (e) {
            // add scan property to window if it does not exist
            if (!window.hasOwnProperty('scan')) {
                window.scan = []
            }

            // if key stroke appears after 10 ms, empty scan array
            if (window.scan.length > 0 && (e.timeStamp - window.scan.slice(-1)[0].timeStamp) > 10) {
                window.scan = []
            }

            // if key store is enter and scan array contains keystrokes
            // dispatch `scanComplete` with keystrokes in detail property
            // empty scan array after dispatching event
            if (e.key === "Enter" && window.scan.length > 0) {
                let scannedString = window.scan.reduce(function (scannedString, entry) {
                    return scannedString + entry.key
                }, "")
                window.scan = []
                return document.dispatchEvent(new CustomEvent('scanComplete', { detail: scannedString }))
            }

            // do not listen to shift event, since key for next keystroke already contains a capital letter
            // or to be specific the letter that appears when that key is pressed with shift key
            if (e.key !== "Shift") {
                // push `key`, `timeStamp` and calculated `timeStampDiff` to scan array
                let data = JSON.parse(JSON.stringify(e, ['key', 'timeStamp']))
                data.timeStampDiff = window.scan.length > 0 ? data.timeStamp - window.scan.slice(-1)[0].timeStamp : 0;

                window.scan.push(data)
            }
        })
        // listen to `scanComplete` event on document
        document.addEventListener('scanComplete', async function (e) {
            tryRegisterAttendance(e.detail);
        });

        return () => {
            document.removeEventListener('keydown', null);
            document.removeEventListener('scanComplete', null);
        }

    }, [gymId]);


    const pressedDigit = (e) => {
        console.log(e, code);
        const newCode = [...code];
        if (e === 'Backspace') {
            newCode.pop();
            setCode(newCode);
        } else if (e === 'Enter') {
            tryRegisterAttendance(code.join(''));
            setCode([]);
        } else {
            newCode.push(e);
            setCode(newCode);
        }
    }

    // useEffect(() => {
    //     window.onkeydown = function (e) { onkeypress(e) };
    //     return () => {
    //         window.onkeydown = undefined;
    //     }
    // }, [code]);

    // const onkeypress = (e) => {
    //     const newCode = [...code];
    //     if (e.key === 'Backspace') {
    //         newCode.pop();
    //         setCode(newCode);
    //     } else if (e.key === 'Enter') {
    //         // tryRegisterAttendance(code.join(''));
    //         // setCode([]);
    //     } else if (e.code.includes('Digit')) {
    //         newCode.push(e.key);
    //         setCode(newCode);
    //     } else {
    //         ///
    //     }
    // }

    const tryRegisterAttendance = async (code) => {
        let audio = new Audio(_audio_success);
        try {
            if (code === '59648574') {
                // logout
                dispatch(signOutUser());
                return;
            }
            const { status, data } = await registerAttendance(accessToken, { code, gymId });
            if (status !== 200) throw true;
            if (data.error) throw data.error;
            if (data.membership.color === 'red') {
                audio = new Audio(_audio_error);
            }
            countDown(data);
        } catch (error) {
            audio = new Audio(_audio_error);
            if (error.message) {
                notification.error({
                    message: 'Error',
                    description: error.message
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'Lo sentimos, tenemos problemas temporales con nuestros servicios.'
                });
            }
        }
        audio.play();
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const countDown = (data) => {
        setClient(data);
        setTimeout(() => setClient(undefined), 3 * 1000);
    };

    // const onScanned = (e) => {
    //     e.preventDefault();
    //     let code = document.getElementById('inputcode')?.value;
    //     // alert(code);
    //     // document.getElementById('inputcode').value = "";
    // }

    return (
        <div className="main-ac">
            <Modal
                closable={false}
                visible={Boolean(client)}
                footer={false}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: 250, height: 250 * 0.75 }}>
                        <img src={client?.client?.image} alt="" style={{ width: 250, height: 250 * 0.75 }} />
                    </div>
                    <h2>{client?.client?.fullName}</h2>
                    <h2>{client?.membership?.name}</h2>
                    <h2 style={{ color: client?.membership?.color }}>{client?.membership?.statusLabel}</h2>
                    <h2>{client?.membership?.expDate}</h2>
                </div>
            </Modal>
            <Modal title="Control de acceso" visible={gymId === undefined || isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div>
                    <h2>Por favor selecciona un Gimnasio para continuar</h2>
                    <Select
                        size='large'
                        value={gymId}
                        onChange={(e) => setGymId(e)}
                        style={{ width: '100%' }}
                    >
                        {user?.gyms?.map((item, i) => {
                            return (<Select.Option key={i.toString()} value={item.id}>{item.name} - {item.branch}</Select.Option>)
                        })}
                    </Select>
                </div>
            </Modal>
            <div className="content-ac">
                <div className="header-ac">{code.join("")}</div>
                {/* <div>
                    <form onSubmit={onScanned}>
                        <input
                            className="header-ac"
                            style={{ width: '100%' }}
                            type='text'
                            id="inputcode"
                        />
                        <input type="submit" hidden />
                    </form>
                </div> */}
                <div className="buttons-ac">
                    <div className="button" onClick={() => pressedDigit(1)}>1</div>
                    <div className="button" onClick={() => pressedDigit(2)}>2</div>
                    <div className="button" onClick={() => pressedDigit(3)}>3</div>

                    <div className="button" onClick={() => pressedDigit(4)}>4</div>
                    <div className="button" onClick={() => pressedDigit(5)}>5</div>
                    <div className="button" onClick={() => pressedDigit(6)}>6</div>

                    <div className="button" onClick={() => pressedDigit(7)}>7</div>
                    <div className="button" onClick={() => pressedDigit(8)}>8</div>
                    <div className="button" onClick={() => pressedDigit(9)}>9</div>

                    <div className="button" onClick={() => pressedDigit('Backspace')}>BORRAR</div>
                    <div className="button" onClick={() => pressedDigit(0)}>0</div>
                    <div className="button" onClick={() => pressedDigit('Enter')}>BUSCAR</div>

                </div>
            </div>
            <div style={{ marginTop: '12px' }}>
                <Button
                    type="primary"
                    icon={<SettingOutlined />}
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                ></Button>
            </div>
        </div>
    )

};

export default AccessControl;