import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import Statistics from './statistics';

const AgendaScreen = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${url}`}>
                <Statistics />
            </Route>
        </Switch>
    )

}

export default AgendaScreen;