import { createSlice } from '@reduxjs/toolkit'

export const posSlice = createSlice({
    name: 'pos',
    initialState: {
        memberships: [],
        products: [],
    },
    reducers: {
        setMemberships: (state, action) => {
            const { payload } = action;
            state.memberships = payload;
        },
        setProducts: (state, action) => {
            const { payload } = action;
            state.products = payload;
        },
    },
})

export const {
    setMemberships,
    setProducts
} = posSlice.actions

export default posSlice.reducer