import './sell.less';
import { Modal, Spin, message } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createSell } from '../../lib/service/sell';
import { formatPrice, showMessageError } from '../../lib/utils';

const calcChange = (price = 0, payment = 0) => {
    return payment === 0 ? 0 : (parseFloat(payment) - parseFloat(price)).toFixed(2) ?? 0;
}

const Sell = ({
    visible,
    onClose,
    onSuccess,
    membership,
    paymentMethod,
    isNewClient,
    client
}) => {

    const { accessToken, shift } = useSelector(state => state.auth);
    const [payment, setPayment] = useState(0);
    const [reference, setReference] = useState('');
    const [creating, setCreating] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        const change = calcChange(membership?.price, payment);

        if (isNewClient) {
            if (
                client.firstName === '' || !client.firstName ||
                client.lastName === '' || !client.lastName ||
                client.phoneNumber === '' || !client.phoneNumber ||
                client.email === '' || !client.email
            ) {
                alert('INSERTE TODA LA INFORMACIÓN DEL CLIENTE.')
                return;
            }
        }
        if (!membership) {
            alert('SELECCIONA UNA MEMBRESIA')
            return;
        }
        if ((change < 0 || payment <= 0) && paymentMethod === 'cash') {
            alert('EL MONTO RECIBIDO NO ES SUFICIENTE.')
            return;
        }
        if (paymentMethod === 'tc') {
            if (!reference.trim()) {
                alert('INSERTE LA REFERENCIA DEL PAGO.')
                return;
            }
        }

        try {
            setCreating(true);

            const values = {
                sell: {
                    client,
                    membership,
                    isNewClient,
                    payment,
                    paymentMethod,
                    change,
                    reference: reference.trim(),
                    gymId: shift.gymId,
                    shiftId: shift.id
                }
            };
            const { status, data } = await createSell(accessToken, values);
            setCreating(false);

            if (status !== 200) {
                showMessageError();
                return;
            }

            if (data.error) {
                showMessageError(data.error.message);
                return;
            }

            message.success('La Venta fue creada con éxito.');
            handleCancel();
            onSuccess(data.data.sell);

        } catch (error) {
            setCreating(false);
            showMessageError();
        }
    }

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        if (creating) return;
        setPayment(0);
        setReference('');
        onClose();
    };

    const price = membership?.price ?? 0;
    const change = calcChange(price, payment);

    if (!visible) return <div></div>

    return (
        <Modal
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
        >
            <Spin spinning={creating}>
                <form onSubmit={onSubmit}>
                    <div className="formInput mb-16">
                        <label>Monto a pagar (MXN): {formatPrice(price)}</label>
                    </div>
                    {paymentMethod === 'cash' && (<div className="formInput mb-16">
                        <label>Monto a recibir:</label>
                        <input
                            type="text"
                            autoFocus
                            onChange={(e) => {
                                let payment = parseFloat(e.target.value);
                                payment = isNaN(payment) ? 0 : payment;
                                setPayment(payment);
                            }}
                        />
                    </div>)}
                    {paymentMethod === 'tc' && (<div className="formInput mb-16">
                        <label>Referencia:</label>
                        <input
                            type="text"
                            autoFocus
                            onChange={(e) => setReference(e.target.value)}
                        />
                    </div>)}
                    {paymentMethod === 'cash' && (<div className="formInput mb-16">
                        <label>Cambio (MXN):{formatPrice(change)}</label>
                    </div>)}
                    <div className="btns">
                        <button className="button" type="submit">Pagar [ENTER]</button>
                        <button className="button" onClick={handleCancel}>Cancelar [ESC]</button>
                    </div>
                </form>
            </Spin>
        </Modal>
    );
}

export default Sell;