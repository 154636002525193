import { useEffect, useState } from 'react';
import { Modal, Select, InputNumber, Spin, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { setShift, signOutUser } from '../../reducers/auth';
import { openShift } from '../../lib/service/shift';
import { showMessageError } from '../../lib/utils'

const { Option } = Select;

const OpenShift = () => {

    const { user, accessToken, shift } = useSelector(state => state.auth);
    const [gym, setGym] = useState(undefined);
    const [cash, setCash] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (shift) {
            setGym(undefined);
            setCash(0);
        }
    }, [shift]);

    const handleChangeGym = (gymId) => {
        setGym(gymId);
    }

    const handleChangeCash = (value) => {
        setCash(value);
    }

    const startShift = async () => {
        if (!gym || cash < 0 || cash === null || cash === undefined) {
            alert('POR FAVOR COMPLETE TODOS LOS CAMPOS');
            return;
        }
        try {
            setSpinning(true);
            const { status, data } = await openShift(accessToken, { gymId: gym, cashAtStart: cash });
            setSpinning(false);
            if (status !== 200) {
                showMessageError();
                return;
            }
            message.success("Has iniciado turno correctamente.");
            dispatch(setShift(data.data.shift));
        } catch (error) {
            setSpinning(false);
        }
    }

    const doLoggedOut = () => {
        dispatch(signOutUser());
    }

    return (
        <Modal
            footer={null}
            visible={!shift}
        >
            <Spin spinning={spinning}>
                <div>
                    <h2>INICIAR TURNO</h2>
                    <h2>Por favor selecciona un Gimnasio para continuar</h2>
                    <Select
                        size='large'
                        value={gym}
                        onChange={handleChangeGym}
                        style={{ width: '100%' }}
                    >
                        {user?.gyms?.map((item, i) => {
                            return (<Option key={i.toString()} value={item.id}>{item.name} - {item.branch}</Option>)
                        })}
                    </Select>
                    <br /><br />
                    <h2>Dinero en Cajon (Fondo de Caja)</h2>
                    <InputNumber
                        size="large"
                        value={cash}
                        onChange={handleChangeCash}
                        style={{ width: '100%' }}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}

                    />
                    <button className='buttonSm' onClick={startShift}>Iniciar Turno</button>
                    <button className='buttonSm' onClick={doLoggedOut}>Salir del Sistema</button>
                </div>
            </Spin>
        </Modal>
    );
};

export default OpenShift;