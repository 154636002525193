import './searchClient.less'

import { useState } from 'react';
import { Modal, Button, Spin } from 'antd';

import { getClients } from '../../lib/service/clients';
import { useSelector } from 'react-redux';

const TakePicture = ({ visible, close, userPicked }) => {

    const { accessToken } = useSelector(state => state.auth);

    const [data, setData] = useState([]);
    const [fullName, setFullName] = useState('');
    const [fetching, toggleFetching] = useState(false);

    const handleCancel = () => {
        setData([]);
        setFullName('');
        close();
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        if (fullName === '') {
            alert('POR FAVOR INTRODUCE EL NOMBRE O EL APELLIDO');
            return;
        }

        try {
            toggleFetching(true);
            const { status, data } = await getClients(accessToken, { filters: { fullName }, pagination: { pageSize: 10 } });
            toggleFetching(false);
            if (status !== 200) {
                return;
            }
            setData(data.data);
        } catch (error) {
            toggleFetching(false);
            console.log(error);
        }
    }

    return (
        <Modal
            footer={null}
            visible={visible}
            onCancel={handleCancel}
            width={720}
        >
            <Spin spinning={fetching}>
                <div className="searchClient">
                    <form onSubmit={handleSubmit}>
                        <div className="flex row center">
                            <div className="formInput mr-12">
                                <label>Nombre(s) o Apellido(s):</label>
                                <input type="text" value={fullName} onChange={e => setFullName(e.target.value)} />
                            </div>
                        </div>
                        <button style={{ display: 'none' }} type="submit">buscar</button>
                    </form>
                    <div className="resultContainer">
                        <table>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre Completo</th>
                                    <th>Teléfono</th>
                                    <th>Correo electrónico</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => {
                                    const onClick = () => {
                                        handleCancel();
                                        userPicked(item.code);
                                    }
                                    return (
                                        <tr key={index.toString()}>
                                            <td><Button type="link" onClick={onClick}>{item.code}</Button></td>
                                            <td><Button type="link" onClick={onClick}>{item.firstName} {item.lastName}</Button></td>
                                            <td>{item.phoneNumber}</td>
                                            <td>{item.email}</td>
                                        </tr>
                                    )
                                })}
                                {data?.length === 0 && (
                                    <tr>
                                        <td colSpan={4} align="center">Sin coincidencias</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default TakePicture;