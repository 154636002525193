import { Layout } from 'antd';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch
} from "react-router-dom";
import { useSelector } from 'react-redux'

import Screens from '../../screens';

import getMenu from './router.js';

const { Content } = Layout;

const ContentC = () => {

    const { path } = useRouteMatch();
    const { user } = useSelector(({ auth }) => auth);

    const renderScreens = (menuItems) => {
        let screens = [];

        for (const menu of menuItems) {
            if (menu.items) {
                screens.push(...renderScreens(menu.items));
            } else {
                let Screen = Screens[menu.url];
                if (!Screen) continue;
                let PATH = `${path}/${menu.url === '/' ? '' : menu.url}`;
                screens.push(
                    <Route key={menu.key} exact={false} path={PATH}>
                        <Screen />
                    </Route>
                );
            }
        }

        return screens;
    }

    const menuItems = getMenu(user?.userType?.aka);
    
    return (
        <Content style={{ margin: '24px 16px 0' }}>
            <Switch>
                <Route
                    exact
                    path={path}
                    render={() => <Redirect to={`${path}/${menuItems[0].url}`} />}
                />
                {renderScreens(menuItems)}
                {/* <Route path="*">
                    <Redirect to={path} />
                </Route>  */}
            </Switch>
        </Content>
    );
}

export default ContentC;