import {
    Row,
    Col,
    Card,
    Tabs,
} from 'antd';

import { useParams } from "react-router-dom";

import GymDetails from '../../../components/gymDetails';
import Gallery from '../../../components/gallery';
import GymBasic from '../../../components/gymBasic';

const { TabPane } = Tabs;

const ProfileScreen = () => {

    const { id } = useParams();

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Card>
                    <Tabs defaultActiveKey="0" onChange={() => null}>
                        <TabPane tab="Información Básica" key="0">
                            <GymBasic gymId={id} />
                        </TabPane>
                        <TabPane tab="Detalles" key="1">
                            <GymDetails gymId={id} />
                        </TabPane>
                        <TabPane tab="Galería" key="2">
                            <Gallery gymId={id} />
                        </TabPane>
                    </Tabs>
                </Card>
            </Col>
        </Row>
    )
};

export default ProfileScreen;