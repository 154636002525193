const DASHBOARD = {
    key: 'dashboard',
    icon: 'PieChartOutlined',
    label: 'Dashboard',
    url: 'dashboard'
};

const ACCESS_CONTROL = {
    key: 'accessControl',
    icon: 'ScanOutlined',
    label: 'Control de Acceso',
    url: 'access-control',
};

const AGENDA = {
    key: 'agenda',
    icon: 'CalendarOutlined',
    label: 'Clases',
    url: 'clases',
};

const POS = {
    key: 'POS',
    icon: 'CalculatorOutlined',
    label: 'POS',
    url: 'pos',
};

const CLIENTS = {
    key: 'clients',
    icon: 'IdcardOutlined',
    label: 'Clientes',
    url: 'clients'
};

const SELLS = {
    key: 'sells',
    icon: 'DollarOutlined',
    label: 'Ventas',
    url: 'sells'
};

const DAILY_CASH_CLOSING = {
    key: 'dailyCashClosing',
    icon: 'FileDoneOutlined',
    label: 'Cortes de Caja',
    url: 'dailyCashClosing'
};

const ADMIN_GYMS = {
    key: 'admin-gyms',
    icon: 'ApartmentOutlined',
    label: 'Gimnasios',
    url: 'admin/gyms'
};

const ADMIN_USERS = {
    key: 'admin-users',
    icon: 'TeamOutlined',
    label: 'Usuarios',
    url: 'admin/users',
};

const ADMIN_MEMBERSHIPS = {
    key: 'admin-memberships',
    icon: 'AppstoreOutlined',
    label: 'Membresias',
    url: 'admin/memberships'
};

const ADMIN_PRODUCTS = {
    key: 'admin-products',
    icon: 'AppstoreAddOutlined',
    label: 'Productos y Servicios',
    url: 'admin/products'
};

const ADMIN_MENU = {
    icon: 'LockOutlined',
    key: 'admin',
    label: 'Admin',
    items: [ADMIN_GYMS, ADMIN_USERS, ADMIN_MEMBERSHIPS, ADMIN_PRODUCTS]
}

const Router = (userType) => {
    // CONTROL DE ACCESO
    if (userType === 'trainer') {
        return [AGENDA]
    }
    // CONTROL DE ACCESO
    if (userType === 'access_control') {
        return [ACCESS_CONTROL]
    }
    // CAJERO
    else if (userType === 'cashier') {
        return [POS]
    }
    // SUPERVISOR
    else if (userType === 'supervisor') {
        return [
            DASHBOARD,
            POS,
            CLIENTS,
            AGENDA,
            SELLS,
            DAILY_CASH_CLOSING,
        ]
    }
    // ADMIN
    else if (userType === 'admin') {
        return [
            DASHBOARD,
            POS,
            AGENDA,
            CLIENTS,
            SELLS,
            DAILY_CASH_CLOSING,
            ADMIN_MENU
        ]
    }
    // SUPER ADMIN
    else if (userType === 'root') {
        return [
            DASHBOARD,
            POS,
            AGENDA,
            CLIENTS,
            SELLS,
            DAILY_CASH_CLOSING,
            ADMIN_MENU
        ]
    }
};

export default Router;