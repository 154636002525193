import { Button, Row, Col } from 'antd';

const AddButton = ({ onClick }) => {
    return (
        <Row className="mb-16">
            <Col className="text-right" span={24}>
                <Button onClick={onClick}>Agregar</Button>
            </Col>
        </Row>
    )
}

export default AddButton;