import { Table } from 'antd';
import * as UTILS from '../../lib/utils/index';

const ClientAttendancs = ({ data }) => {

    return (
        <>
            <Table
                dataSource={data.sort((a, b) => b.id - a.id)}
                rowKey={'id'}
                columns={[
                    {
                        title: 'Gimnasio',
                        dataIndex: '',
                        key: '',
                        render: (_, record) => record?.Gym?.name + ' - ' + record?.Gym?.branch
                    },
                    {
                        title: 'Fecha',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (value) => UTILS.dateToString(value, true)
                    },
                ]}
            ></Table>
        </>
    )
};

export default ClientAttendancs;