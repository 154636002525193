import { useEffect, useState } from 'react';
import { Modal, InputNumber, Spin, Row, Col, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import * as shiftService from '../../lib/service/shift';
import { showMessageError } from '../../lib/utils';
import { setShift } from '../../reducers/auth';

const GymPicker = ({ visible, onClose }) => {

    const dispatch = useDispatch();
    const { accessToken, shift } = useSelector(state => state.auth);

    const [spinning, setSpinning] = useState(false);
    const [expected, setExpected] = useState({ cash: 0, tc: 0 });
    const [cashInCashier, setCashInCashier] = useState(0);

    const closeModal = () => {
        onClose();
    }

    // const keepShift = () => {
    //     // we pass true to loggedout
    //     onClose(true);
    // }

    const closeShift = () => {
        attemptToCloseShift();
    }

    useEffect(() => {
        if (visible) {
            attemptGetCash();
        }
    }, [visible]);

    const attemptGetCash = async () => {
        try {
            setSpinning(true);
            const { status, data } = await shiftService.getCash(
                accessToken,
                {
                    shift: {
                        id: shift.id
                    }
                });
            setSpinning(false);
            if (status !== 200 || data.error) {
                showMessageError();
                return;
            }
            setExpected(data.data);
        } catch (error) {
            setSpinning(false);
            showMessageError();
        }
    }

    const attemptToCloseShift = async () => {
        try {
            setSpinning(true);
            const { status, data } = await shiftService.closeShift(
                accessToken,
                {
                    shift: {
                        id: shift.id,
                        cashAtEnd: cashInCashier
                    }
                }
            );
            setSpinning(false);
            if (status !== 200 || data.error) {
                showMessageError();
                return;
            }
            // print ticket
            
            // remove shift from session
            dispatch(setShift(null));
            // close modal
            closeModal();

        } catch (error) {
            setSpinning(false);
            showMessageError();
        }
    }

    return (
        <Modal
            footer={null}
            visible={visible}
            onCancel={closeModal}
        >
            <Spin spinning={spinning}>
                <div>
                    <h2>CIERRE DE TURNO</h2>
                    <h3>Por favor reviza el dinero en Caja e ingrésalo para continuar con el cierre de turno.</h3>
                    <br />
                    <Row gutter={[24, 24]}>
                        <Col span={12}><h3>Ingreso esperado en Terminal Bancaria</h3></Col>
                        <Col span={12}>
                            <InputNumber
                                disabled={true}
                                size="large"
                                style={{ width: '100%' }}
                                value={expected.tc}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                        <Divider style={{ margin: 0 }} />
                        <Col span={12}><h3>Efectivo esperado en Caja</h3></Col>
                        <Col span={12}>
                            <InputNumber
                                disabled={true}
                                size="large"
                                style={{ width: '100%' }}
                                value={expected.cash}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                        <Col span={12}><h3>Cuánto efectivo hay en Caja?</h3></Col>
                        <Col span={12}>
                            <InputNumber
                                size="large"
                                style={{ width: '100%' }}
                                defaultValue={0}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={value => {
                                    setCashInCashier(value);
                                }}
                            />
                        </Col>
                        <Col span={12}><h3>Diferencia</h3></Col>
                        <Col span={12}>
                            <InputNumber
                                disabled={true}
                                size="large"
                                style={{ width: '100%' }}
                                value={expected.cash - cashInCashier}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Col>
                        {/* <Col span={12}>
                            <button className="buttonSm" onClick={keepShift}>Vuelvo pronto</button>
                        </Col> */}
                        <Col span={24}>
                            <button className="buttonSm" onClick={closeShift}>Cerrar turno</button>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </Modal>
    );
};

export default GymPicker;