import API from '../api';

export const openShift = (token, values) => {
    return API(token).post('/shifts/openShift', values);
}

export const getCash = (token, values) => {
    return API(token).post('/shifts/getCash', values);
}

export const closeShift = (token, values) => {
    return API(token).post('/shifts/closeShift', values);
}