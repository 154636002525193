import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import Table from './table';
import Profile from './profile';

const ClientsScreen = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${url}`}>
                <Table />
            </Route>
            <Route exact path={`${url}/:id`}>
                <Profile />
            </Route>
        </Switch>
    )

}

export default ClientsScreen;