import { Button, Divider, Tag, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import AdminPage from '../../../components/adminPage';
import UserModal from '../../../components/userModal';

import { showMessageError } from '../../../lib/utils';
import { deleteUser } from '../../../lib/service/user';

const MembershipScreen = () => {

    const pageTitle = "Administración de Usuarios";

    const { accessToken } = useSelector(state => state.auth);

    const renderName = (_, record) => {
        const { firstName, lastName } = record;
        return `${firstName} ${lastName}`;
    }

    const renderUserRol = (value) => {
        let text = '-';
        if (value === 2) text = 'Administrador';
        if (value === 3) text = 'Supervisor';
        if (value === 4) text = 'Cajero';
        if (value === 5) text = 'Control de acceso';
        if (value === 6) text = 'Instructor';
        return <Tag>{text}</Tag>
    }

    const renderGyms = (value) => {
        return value.map((value, index) => {
            return (<div key={`${index}`} style={{ marginBottom: '4px' }}><Tag>{value}</Tag></div>)
        });
    }

    const renderCtrls = (value, record, index, openRecord, showPromiseConfirm, reloadTable) => {
        const options = {
            title: `¿Esta seguro de elimar a ${record.firstName} ${record.lastName}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'Esta es una acción que no se puede deshacer.',
            onOk() {
                return deleteUser(accessToken, { user: { id: record.id } })
                    .then(() => {
                        message.success(`El usuario ha sido eliminado con éxito.`);
                        reloadTable();
                    })
                    .catch(() => {
                        showMessageError();
                    });
            },
            onCancel() { },
        }
        return (
            <>
                <Button danger onClick={() => showPromiseConfirm(options)}>Eliminar</Button>
                <Divider type="vertical" />
                <Button onClick={() => openRecord(record)}>Editar</Button>
            </>
        );
    }

    const columns = [
        { key: 'name', title: 'Nombre Completo', render: renderName },
        { key: 'phoneNumber', title: 'Número Celular' },
        { key: 'userTypeId', title: 'Rol', render: renderUserRol },
        { key: 'gymNames', title: 'Gimnasios', render: renderGyms },
        { key: 'controls', title: 'Controles', align: 'right', render: renderCtrls },
    ];

    const filters = [
        { key: 'fullName', title: 'Nombre' },
        {
            key: 'userTypeId',
            title: 'Rol',
            options: [
                { value: 5, label: 'Control de Acceso' },
                { value: 4, label: 'Cajero' },
                { value: 3, label: 'Supervisor' },
                { value: 2, label: 'Administrador' },
            ]
        },
    ];

    const endpoint = '/users/list';

    return (
        <AdminPage
            pageTitle={pageTitle}
            columns={columns}
            filters={filters}
            endpoint={endpoint}
            Modal={UserModal}
        />
    );
}

export default MembershipScreen;