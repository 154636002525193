import {
    Modal,
    Form,
    Row,
    Col,
    InputNumber,
    Select,
    DatePicker,
    TimePicker,
    Input,
    Switch,
    Checkbox
} from 'antd';

import { useState, forwardRef, useEffect, useImperativeHandle } from 'react';

import { useSelector } from 'react-redux';

import * as ClassesService from './../../lib/service/classes';
import * as CatalogService from './../../lib/service/cat';
import * as UserService from './../../lib/service/user';
import * as GymService from './../../lib/service/gym';
import * as MembershipService from './../../lib/service/membership';

const CheckboxGroup = Checkbox.Group;

const ClassModal = (props, ref) => {
    const { accessToken } = useSelector(state => state.auth);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [services, setServices] = useState([]);
    const [trainers, setTrainers] = useState([]);
    const [gyms, setGyms] = useState([]);
    const [memberships, setMemberships] = useState([]);
    const [repeatClass, setRepeatClass] = useState(false);

    useEffect(() => {
        initialData();
    }, []);

    const initialData = () => {
        const userPayload = {
            pagination: { pageSize: 50 },
            filters: [{ key: 'type', op: 'eq', value: 6 }]
        };
        const gymPayload = {
            pagination: { pageSize: 50 },
            filters: [{ key: 'status', op: 'eq', value: 'active' }]
        };
        const membershipPayload = {
            pagination: { pageSize: 50 },
            filters: [{ key: 'status', op: 'eq', value: 'active' }]
        };
        Promise.all([
            CatalogService.getCatServices(accessToken),
            UserService.listUser(accessToken, userPayload),
            GymService.getGyms(accessToken, gymPayload),
            MembershipService.list(accessToken, membershipPayload),
        ])
            .then(values => {
                setServices(values[0].data.data);
                setTrainers(values[1].data.data);
                setGyms(values[2].data.data);
                setMemberships(values[3].data.data);
            })
            .catch(err => console.log(err))
            .finally();
    }

    const onOk = () => {
        form
            .validateFields()
            .then(async values => {
                const params = { ...values };
                params.date = values.date.format('YYYY-MM-DD');
                params.startTime = values.startTime.format('HH:mm:ss');
                params.endTime = values.endTime.format('HH:mm:ss');
                params.repeatUntil = values.repeatUntil?.format('YYYY-MM-DD');
                setFetching(true);
                try {
                    const response = await ClassesService.create(accessToken, params);
                    if (response.status === 200) {
                        onCancel();
                        if (props.onCreateCallback) {
                            props.onCreateCallback(response.data.data);
                        }
                    }
                } catch (error) {

                }
                setFetching(false);
            })
            .catch(err => null);
    };

    const onCancel = () => {
        if (fetching) return;
        form.resetFields();
        setVisible(false);
        setRepeatClass(false);
    };

    useImperativeHandle(ref, () => {
        return {
            open: () => {
                setVisible(true);
            }
        }
    },);

    const onValuesChangeForm = ({ repeat }) => {
        if (repeat !== undefined && repeat !== repeatClass)
            setRepeatClass(repeat);
    }

    return (
        <div>
            <Modal
                visible={visible}
                title="Nueva Clase"
                onOk={onOk}
                onCancel={onCancel}
                confirmLoading={fetching}
            >
                <Form form={form} layout='vertical' onValuesChange={onValuesChangeForm}>
                    <Row gutter={[24]}>
                        <Col span={12}>
                            <Form.Item
                                name="serviceId"
                                label="Categoria"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una categoria"
                                    allowClear
                                >
                                    {services.map(function (s) {
                                        return (<Select.Option key={s.id} value={s.id}>{s.description}</Select.Option>)
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="level"
                                label="Nivel"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una nivel"
                                    allowClear
                                >
                                    <Select.Option value="beginner">Principiante</Select.Option>
                                    <Select.Option value="intermediate">Intermedio</Select.Option>
                                    <Select.Option value="advanced">Avanzado</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="gymId"
                                label="Gimnasio"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un gimnasio"
                                    allowClear
                                >
                                    {gyms.map(function (item) {
                                        return (<Select.Option key={item.id} value={item.id}>
                                            {item.name} - {item.branch}
                                        </Select.Option>);
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="instructorId"
                                label="Instructor"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un instructor"
                                    allowClear
                                >
                                    {trainers.map(function (item) {
                                        return (<Select.Option key={item.id} value={item.id}>
                                            {item.firstName} {item.lastName}
                                        </Select.Option>);
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="date"
                                label="Fecha"
                                rules={[{ required: true }]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Año-Mes-Día" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24]}>
                        <Col span={12}>
                            <Form.Item
                                name="startTime"
                                label="Hora de inicio"
                                rules={[{ required: true }]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    placeholder="Hora de inicio"
                                    use12Hours
                                    format={'hh:mm a'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="endTime"
                                label="Hora de termino"
                                rules={[{ required: true }]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    placeholder="Hora de termino"
                                    use12Hours
                                    format={'hh:mm a'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="price"
                                label="Precio"
                                rules={[{ required: true }]}
                            >
                                <InputNumber
                                    placeholder='-'
                                    style={{ width: '100%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Descripcíon"
                                rules={[{ required: true }]}
                            >
                                <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="membershipIds"
                                label="Incluir esta clase en las siguientes membresías"
                                rules={[{ required: false }]}
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    options={memberships?.map((item) => {
                                        return {
                                            value: item.id,
                                            label: `[${item.code}] - ${item.name}`
                                        }
                                    })}
                                    placeholder=""
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                name="repeat"
                                label="Repetir"
                                rules={[{ required: false }]}
                                initialValue={false}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren="Si" unCheckedChildren="No" />
                            </Form.Item>
                        </Col>
                        {repeatClass && (
                            <>
                                <Col span={9}>
                                    <Form.Item
                                        name="repeatOn"
                                        label="Repetir los"
                                        valuePropName="checked"
                                        rules={[{ required: repeatClass, message: 'Por favor selecciona almenos un día' }]}
                                    >
                                        <CheckboxGroup
                                            options={[
                                                { label: 'Lunes', value: 1 },
                                                { label: 'Martes', value: 2 },
                                                { label: 'Miércoles', value: 3 },
                                                { label: 'Jueves', value: 4 },
                                                { label: 'Viernes', value: 5 },
                                                { label: 'Sábado', value: 6 },
                                                { label: 'Domingo', value: 0 }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        name="repeatUntil"
                                        label="Repetir hasta la fecha"
                                        rules={[{ required: repeatClass, message: 'Por favor selecciona la fecha de terminación' }]}
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="Año-Mes-Día" />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                </Form>
            </Modal>
        </div>
    )
};

export default forwardRef(ClassModal);