import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    Button,
} from 'antd';

import { useState } from 'react';

import { useSelector } from 'react-redux';

import {
    PlusSquareOutlined
} from '@ant-design/icons';

import PickImage from './../pickImage';

import * as ClientService from './../../lib/service/clients';
import { formatInputPhoneNumber } from '../../lib/utils';

const ClientModal = ({ onCreateUser, gymId }) => {
    const { accessToken } = useSelector(state => state.auth);
    const [form] = Form.useForm();
    const [picture, setPicture] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [fetching, setFetching] = useState(false);

    const onOk = () => {
        form
            .validateFields()
            .then(async values => {
                const params = {
                    ...values,
                    phoneNumber: `+52${values.phoneNumber}`,
                    gymId,
                    picture
                };
                setFetching(true);
                try {
                    const response = await ClientService.create(accessToken, params);
                    if (response.status === 200) {
                        onCancel();
                        onCreateUser(response.data.data);
                    }
                } catch (error) {

                }
                setFetching(false);
            })
            .catch(err => null);
    };

    const onCancel = () => {
        if (fetching) return;
        form.resetFields();
        setPicture(undefined);
        setVisible(false);
    };

    const onTakePicture = (base64) => {
        setPicture(base64);
    }

    return (
        <div>
            <Button
                htmlType='submit'
                type="primary" block
                icon={<PlusSquareOutlined />}
                onClick={() => setVisible(true)}
            >NUEVO</Button>
            <Modal
                visible={visible}
                title="Nuevo Cliente"
                onOk={onOk}
                onCancel={onCancel}
                confirmLoading={fetching}
            >
                <Form form={form} layout='vertical'>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <PickImage value={picture} onTakePicture={onTakePicture} />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="firstName"
                                label="Nombre(s)"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <Input placeholder="Nombre(s)" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastName"
                                label="Apellidos(s)"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <Input placeholder="Apellido(s)" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phoneNumber"
                                label="Número de celular"
                                normalize={(value) => {
                                    return formatInputPhoneNumber(value);
                                }}
                                rules={[
                                    { required: true },
                                    { len: 13, message: 'Número de celular invalido' }
                                ]}
                            >
                                <Input placeholder='Número de celular a 10 dígitos' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Correo Electrónico"
                                rules={[
                                    { required: true, type: 'email', message: 'Correo Electrónico invalido' }
                                ]}
                            >
                                <Input placeholder='correo@email.com' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
};

export default ClientModal;