import { Redirect } from "react-router-dom"
import { useSelector } from 'react-redux'
import { Layout } from "antd";

import Menu from './menu';
import Content from './content';
import Header from './header';

const App = () => {

    const auth = useSelector(({ auth }) => auth);

    if (!auth.logged)
        return (<Redirect to={`/auth`} />);

    /**
     * Para Cajeros y Control de Acceso
     * solo mostramos el modulo
     */
    if (auth?.user?.userType?.aka === 'access_control')
        return <Content />

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Menu />
            <Layout>
                <Header />
                <Content />
            </Layout>
        </Layout>
    );
}

export default App