import SellDescription from "../../components/sellDescription";

import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { getRcpt } from '../../lib/service/sell';

const RcptcScreen = () => {

    const { id } = useParams();
    const [record, setRecord] = useState({});

    useEffect(() => {

        getRcpt(id)
            .then(({ status, data }) => {
                if (status !== 200) {
                    return;
                }
                setRecord(data.data);
            })
            .catch(err => {
                console.log(err);
            })

    }, [id]);

    return (
        <div style={{
            backgroundColor: '#2b669f',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
        }}>

            <div style={{
                backgroundColor: '#fff',
                borderRadius: 8,
                padding: 10,
                maxWidth: '400px',
                width: '90%'
            }}>
                <SellDescription record={record} />
            </div>

        </div >
    )

}

export default RcptcScreen;