import './index.less';

import {
    useEffect,
    useState
} from 'react';

import { useSelector } from 'react-redux';

import {
    Row,
    Col,
    Card,
    Table,
    Avatar
} from 'antd';

import { UserOutlined } from '@ant-design/icons'

import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer
} from 'recharts';

import { formatPrice } from '../../lib/utils/index';
import { getStatistics } from '../../lib/service/dashboard';
import PageWrapper from "../../components/pageWrapper";

import AdvancedFilter from '../../components/advancedFilter';

const DashboardScreen = () => {

    const { user, accessToken } = useSelector(store => store.auth);

    const [dashboard, setDashboard] = useState({
        subsToday: [],
        subsByName: [],
        sellsByMonth: [],
        sellsTodayCash: 0,
        sellsTodayTc: 0,
        sellsTodayOnline: 0,
    });

    const today = new Date();
    const year = today.getFullYear();

    useEffect(() => {

        loadStatistics({ gymId: user?.gyms?.[0]?.id });

    }, []);

    const loadStatistics = (payload) => {
        getStatistics(accessToken, payload)
            .then(({ status, data }) => {
                if (status !== 200) {
                    return;
                }
                setDashboard({
                    sellsByMonth: data.data.sellsByMonth,
                    sellsTodayCash: data.data.sellsTodayCash,
                    sellsTodayTc: data.data.sellsTodayTc,
                    sellsTodayOnline: data.data.sellsTodayOnline,
                    subsToday: data.data.subsToday,
                    subsByName: data.data.subsByName
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <PageWrapper title="Dashboard">
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <AdvancedFilter
                        fields={[
                            {
                                key: 'gymId',
                                title: 'Gimnasio',
                                initialValue: user?.gyms?.[0]?.id,
                                options: user?.gyms?.map((gym) => {
                                    return { value: gym.id, label: `${gym.name} - ${gym.branch}` };
                                }) ?? []
                            }
                        ]}
                        callbackFunc={(filters) => {
                            loadStatistics({ gymId: filters.gymId });
                        }}
                    />
                </Col>
                <Col xs={24} md={6}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '20px 24px 8px 24px' }}
                        className="infoCard"
                    >
                        <div className="meta">Ventas en Efectivo Hoy</div>
                        <div className="total">{formatPrice(dashboard.sellsTodayCash)}</div>
                        <div className="content"></div>
                    </Card>
                </Col>
                <Col xs={24} md={6}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '20px 24px 8px 24px' }}
                        className="infoCard"
                    >
                        <div className="meta">Ventas por Terminal Bancaria Hoy</div>
                        <div className="total">{formatPrice(dashboard.sellsTodayTc)}</div>
                        <div className="content"></div>
                    </Card>
                </Col>
                <Col xs={24} md={6}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '20px 24px 8px 24px' }}
                        className="infoCard"
                    >
                        <div className="meta">Ventas por Olympia App Hoy</div>
                        <div className="total">{formatPrice(dashboard.sellsTodayOnline)}</div>
                        <div className="content"></div>
                    </Card>
                </Col>
                <Col xs={24} md={6}>
                    <Card
                        bordered={false}
                        bodyStyle={{ padding: '20px 24px 8px 24px' }}
                        className="infoCard"
                    >
                        <div className="meta">Gran Total Hoy</div>
                        <div className="total">{formatPrice(dashboard.sellsTodayOnline + dashboard.sellsTodayTc + dashboard.sellsTodayCash)}</div>
                        <div className="content"></div>
                    </Card>
                </Col>
                {/* 
                
                
                
                */}
                <Col span={6}>
                    <Card title={`Clientes por Membresía`} bordered={false}>
                        <Table
                            size='small'
                            columns={[
                                { key: 'name', dataIndex: 'name', title: 'Membresía' },
                                { key: 'total', dataIndex: 'total', title: '# Clientes', align: 'center' },
                            ]}
                            dataSource={dashboard.subsByName ?? []}
                            rowKey={record => record.id}
                        />
                    </Card>
                </Col>
                <Col span={18}>
                    <Card title={`Nuevos Miembros`} bordered={false}>
                        <Table
                            size='small'
                            columns={[
                                {
                                    key: 'clientImage',
                                    dataIndex: 'clientImage',
                                    title: '',
                                    render: function (value) {
                                        return (
                                            <Avatar size={48} src={value} icon={<UserOutlined />} />
                                        )
                                    }
                                },
                                { key: 'clientCode', dataIndex: 'clientCode', title: 'Código' },
                                { key: 'clientName', dataIndex: 'clientName', title: 'Cliente' },
                                { key: 'clientPhone', dataIndex: 'clientPhone', title: 'Teléfono' },
                                { key: 'clientEmail', dataIndex: 'clientEmail', title: 'Correo electrónico' },
                                { key: 'membershipName', dataIndex: 'membershipName', title: 'Membresía' },
                                { key: 'gymName', dataIndex: 'gymName', title: 'Gimnasio' },
                            ]}
                            dataSource={dashboard.subsToday ?? []}
                            rowKey={record => record.id}
                        />
                    </Card>
                </Col>
                {/* 
                
                
                
                */}
                <Col span={24}>
                    <Card title={`Ventas por mes - ${year}`} bordered={false}>
                        <ResponsiveContainer width='100%' height={430}>
                            <BarChart data={dashboard.sellsByMonth}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip formatter={(value) => [formatPrice(value), 'Total']} />
                                <Legend />
                                <Bar
                                    dataKey="total"
                                    fill="#2b669f"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    )
}

export default DashboardScreen;