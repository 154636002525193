import {
    Switch,
    Route,
    Redirect,
    useRouteMatch
} from "react-router-dom"

import { useSelector } from 'react-redux'

import SigninScreen from '../../screens/signin'
import SignupScreen from '../../screens/signup'

const Auth = () => {

    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    const { path } = useRouteMatch();

    const auth = useSelector(({ auth }) => auth);

    if (auth.logged)
        return (<Redirect to={`/app`} />);

    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${path}/signin`} />
            </Route>
            <Route exact path={`${path}/signin`}>
                <SigninScreen />
            </Route>
            <Route exact path={`${path}/signup`}>
                <SignupScreen />
            </Route>
            <Route path="*">
                <Redirect to={`${path}/signin`} />
            </Route>
        </Switch>
    );
}

export default Auth