import React, { useEffect, useState } from 'react';

import {
    Row, Col,
    Form, Input,
    Button,
    TimePicker,
    Select,
    notification
} from 'antd';

import { useSelector } from 'react-redux';

import * as GYM from './../../lib/service/gym';
import * as CAT from './../../lib/service/cat';
import moment from 'moment';

const GymDetails = ({ gymId }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const { accessToken } = useSelector(store => store.auth);

    useEffect(function () { if (gymId) getData(); }, [gymId]);
    useEffect(function () { getCatServices(); }, []);

    const handleOnSubmit = (values) => {
        setLoading(true);
        GYM.updateDetails(accessToken, {
            ...values,
            openingHours: values.openingHours.format('HH:mm'),
            closingHours: values.closingHours.format('HH:mm'),
            gymId
        })
            .then((response) => {
                if (response.status === 200) {
                    notification.success({ message: "Información actualizada." });
                }
            })
            .catch(err => {
                notification.error({ message: "Servicio no disponible." });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCatServices = () => {
        CAT.getCatServices(accessToken)
            .then((resp) => {
                const { status, data } = resp;
                if (status === 200) {
                    if (data.data) {
                        setOptions(data.data.map(service => {
                            return {
                                label: service.description,
                                value: service.description
                            }
                        }));
                    }
                }
            })
            .catch(err => {
                return null;
            })
            .finally(() => {
                return null;
            });
    }

    const getData = () => {
        GYM.getDetails(accessToken, { gymId })
            .then((resp) => {
                const { status, data } = resp;
                if (status === 200) {
                    if (data.data) {
                        form.setFieldsValue({
                            description: data.data.description,
                            email: data.data.email,
                            services: data.data.services?.split(','),
                            latitude: data.data.latitude,
                            longitude: data.data.longitude,
                            openingHours: moment(data.data.openingHours, "HH:mm"),
                            closingHours: moment(data.data.closingHours, "HH:mm")
                        });
                    }
                }
            })
            .catch(err => {
                return null;
            })
            .finally(() => {
                return null;
            });
    }

    return (
        <Form form={form} onFinish={handleOnSubmit} layout="vertical">
            <Row gutter={[24]}>
                <Col span={6}>
                    <Form.Item
                        name="openingHours"
                        label="Apertura"
                        rules={[{ required: true, message: 'No olvides la Hora!' }]}
                    >
                        <TimePicker use12Hours format={'HH:mm a'} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="closingHours"
                        label="Cierre"
                        rules={[{ required: true, message: 'No olvides la Hora!' }]}
                    >
                        <TimePicker use12Hours format={'HH:mm a'} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name="description"
                        label="Descripción"
                        rules={[{ required: true, message: 'No olvides los servicios!' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Como por ejemplo: Conoce todos nuestros..." />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name="services"
                        label="Servicios"
                        rules={[{ required: true, message: 'No olvides los Servicios!' }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona los servicios"
                            options={options}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24]}>
                <Col span={12}>
                    <Form.Item
                        name="email"
                        label="Correo Electrónico"
                        rules={[{ required: false, message: 'No olvides!' }]}
                    >
                        <Input placeholder='tucorreo@email.com' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24]}>
                <Col span={6}>
                    <Form.Item
                        name="latitude"
                        label="Latitude"
                        rules={[{ required: false, message: 'No olvides la Latitud!' }]}
                    >
                        <Input placeholder='26.0118' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="longitude"
                        label="Longitude"
                        rules={[{ required: false, message: 'No olvides la Longitud!' }]}
                    >
                        <Input placeholder='-111.348' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Button loading={loading} type="primary" htmlType="submit" style={{ float: 'right' }}>Guardar</Button>
                </Col>
            </Row>
        </Form>

    );
};

export default GymDetails;