import './takePicture.less';
import { useEffect } from 'react';
import { Modal } from 'antd';

const TakePicture = ({ visible, close, pictureTaken }) => {

    var stream,
        video,
        canvas;

    useEffect(() => {
        if (visible) {
            turnOnCamera();
        }
    }, [visible]);

    const turnOnCamera = async () => {
        video = document.querySelector("#video");
        canvas = document.querySelector("#canvas");

        stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        video.srcObject = stream;
        video.play();
    }

    const turnOffCamera = () => {
        stream?.getTracks().forEach(function (track) {
            if (track.readyState === 'live' && track.kind === 'video') {
                track.stop();
            }
        })
    }

    const handleCancel = () => {
        turnOffCamera();
        close();
    }

    const takePicture = () => {
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        let imgDataUrl = canvas.toDataURL('image/jpeg');
        handleCancel();
        pictureTaken(imgDataUrl);
    }

    return (
        <Modal
            footer={null}
            visible={visible}
            onCancel={handleCancel}
        >
            <div id="takePicture">
                <div className="top">
                    <video id="video" width="320" height="240"></video>
                    <button className="button mt-16" onClick={takePicture}>Tomar Foto</button>
                    <canvas id="canvas" width="320" height="240" style={{ display: 'none' }}></canvas>
                </div>
            </div>
        </Modal>
    );
};

export default TakePicture;