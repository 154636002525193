import olympiaLogo from "./../../img/Olympia_Logo-01.svg";

import { formatInputPhoneNumber } from '../../lib/utils';

import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Typography,
    Spin,
    notification
} from 'antd';

import { useEffect, useState } from 'react';

import * as UserService from './../../lib/service/user';

import { useHistory } from "react-router-dom"

const { Title } = Typography;

const StepOne = ({ handlerOnSuccess, initialValues }) => {

    const [form] = Form.useForm();

    const handlerOnSubmit = () => {
        form
            .validateFields()
            .then(values => {
                handlerOnSuccess(values);
            })
            .catch(err => null);
    }

    useEffect(() => {
        form.setFieldsValue({
            firstName: initialValues?.firstName ?? '',
            lastName: initialValues?.lastName ?? '',
            phoneNumber: initialValues?.phoneNumber ?? '',
            email: initialValues?.email ?? '',
            username: initialValues?.username ?? '',
            password: initialValues?.password ?? '',
        });
    }, []);

    return (
        <Form form={form} layout="vertical">
            <Title level={3} style={{ textAlign: 'center' }}>Paso 1 de 3: Datos Personales</Title>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="firstName"
                        label="Nombre(s)"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Nombre(s)" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="lastName"
                        label="Apellido(s)"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Apellido(s)" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="phoneNumber"
                        label="Número de Celular"
                        normalize={(value) => {
                            return formatInputPhoneNumber(value);
                        }}
                        rules={[
                            { required: true },
                            { len: 13, message: 'Número de celular invalido' }
                        ]}
                    >
                        <Input prefix="🇲🇽" placeholder="Número a 10 dígitos" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="email"
                        label="Correo Electrónico"
                        rules={[{ required: true, type: 'email' }]}
                    >
                        <Input placeholder="tucorreo@email.com" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="username"
                        label="Usuario"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Usuario Olympia GMS" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="password"
                        label="Contraseña"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Contraseña Olympia GMS" type="password" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <br />
                    <Button type="primary" block onClick={handlerOnSubmit}>Continuar</Button>
                </Col>
            </Row>
        </Form>
    )
};

const StepTwo = ({ handlerOnSuccess, initialValues }) => {

    const [form] = Form.useForm();

    const handlerOnSubmit = () => {
        form
            .validateFields()
            .then(values => {
                handlerOnSuccess(values);
            })
            .catch(err => null);
    }

    useEffect(() => {
        form.setFieldsValue({
            gymName: initialValues?.gymName ?? '',
            gymBranch: initialValues?.gymBranch ?? '',
            gymAddress: initialValues?.gymAddress ?? '',
            gymBusinessName: initialValues?.gymBusinessName ?? '',
            gymRfc: initialValues?.gymRfc ?? '',
            gymPhoneNumber: initialValues?.gymPhoneNumber ?? '',
        });
    }, []);

    return (
        <Form form={form} layout="vertical">
            <Title level={3} style={{ textAlign: 'center' }}>Paso 2 de 3: Datos de tu Gimnasio</Title>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="gymName"
                        label="Nombre de tu Gimnasio"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Nombre de tu Gimnasio" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="gymBranch"
                        label="Sucursal"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Sucursal" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="gymAddress"
                        label="Dirección"
                        rules={[
                            { required: true },
                        ]}
                    >
                        <Input placeholder="Calle Número, Colonia, Código Postal Ciudad, Estado, País" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="gymBusinessName"
                        label="Razón Social"
                        rules={[{ required: true, }]}
                    >
                        <Input placeholder="Razón Social" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="gymRfc"
                        label="RFC"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="RFC" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="gymPhoneNumber"
                        label="Número de Teléfono"
                        normalize={(value) => {
                            return formatInputPhoneNumber(value);
                        }}
                        rules={[
                            { required: true },
                            { len: 13, message: 'Número de teléfono invalido' }
                        ]}
                    >
                        <Input prefix="🇲🇽" placeholder="Número a 10 digitos" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <br />
                    <Button type="primary" block onClick={handlerOnSubmit}>Continuar</Button>
                </Col>
            </Row>
        </Form>
    )
};

const StepThree = ({ handlerOnSuccess, initialValues }) => {

    const [logo, setLogo] = useState({
        file: initialValues?.logo ?? undefined,
        base64: initialValues?.logo ?? undefined
    });

    const handlerOnSubmit = () => {
        handlerOnSuccess({ logo: logo.base64 });
    }

    const handleOnChangeFile = (evt) => {
        if (evt.target.files?.[0]) {
            const FR = new FileReader();

            FR.addEventListener("load", function (e) {
                setLogo({
                    base64: e.target.result,
                    file: evt.target.files[0]
                });
            });

            FR.readAsDataURL(evt.target.files[0]);
        }
    }

    return (
        <div>
            <Title level={3} style={{ textAlign: 'center' }}>Paso 3 de 3: Logo de tu Gimnasio</Title>
            <Row gutter={24}>
                <Col span={24}>
                    <label htmlFor="gymLogo" style={{ display: 'block', cursor: 'pointer' }}>
                        <div style={{
                            backgroundColor: 'grey',
                            width: '200px',
                            height: '200px',
                            margin: '0 auto',
                            borderRadius: '12px',
                            overflow: 'hidden',
                            position: 'relative'
                        }}>
                            <img style={{ width: "200px", height: "200px" }} src={logo.base64} />
                            <span style={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '10px',
                                right: '10px',
                                textAlign: 'center',
                                background: '#00000057',
                                color: 'white'
                            }}>Da Click para subir logo</span>
                        </div>
                    </label>
                    <input
                        type="file"
                        id="gymLogo"
                        onChange={handleOnChangeFile}
                        style={{ display: 'none' }}
                    />
                </Col>
                <Col span={24}>
                    <br />
                    <Button
                        disabled={logo.file === undefined}
                        block={true} type="primary"
                        onClick={handlerOnSubmit}
                    >Continuar</Button>
                </Col>
            </Row>
        </div>
    )
};

const StepFive = ({ handlerOnSuccess }) => {

    const [form] = Form.useForm();

    const handlerOnSubmit = () => {
        form
            .validateFields()
            .then(values => {
                handlerOnSuccess(values);
            })
            .catch(err => null);
    }

    return (
        <div>
            <Title level={3} style={{ textAlign: 'center' }}>Código de Verificación</Title>
            <Form form={form} layout="vertical">
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="otp"
                            label="Código"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Código" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Button block onClick={handlerOnSubmit}>Continuar</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
};

const SignupScreen = (props) => {

    const history = useHistory();
    const [step, setStep] = useState(1);
    const [userId, setUserId] = useState(undefined);
    const [values, setValues] = useState({});

    const handlerOnSuccessStep = (stepValues) => {
        setValues({ ...values, [step]: stepValues });
        setStep(step + 1);
    }

    useEffect(() => {
        if (step == 4) {
            handlerOnFinish();
        }
        if (step === 6) {
            signUpOtp()
        }
    }, [step]);

    const handlerOnFinish = () => {
        const data = {
            ...values[1],
            ...values[2],
            ...values[3],
        };
        UserService.attemptingSignUp(data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        if (response.data.error.code === 'username_already_taken') {
                            notification.error({ message: 'El nombre de usuario ya ha sido usado, por favor selecciona otro.' });
                            setStep(1);
                        }
                    } else {
                        setUserId(response.data.data.id);
                        setStep(step + 1);
                    }
                }
            })
            .catch(err => {
                setStep(3);
            })
            .finally(() => null);
    };

    const signUpOtp = () => {
        const data = {
            ...values[5],
            userId,
        };
        UserService.signUpOtp(data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.success) {
                        // navigate to signin
                        notification.success({ message: 'Tu cuenta fue creada con éxito, utiliza tu usuario y contraseña para iniciar sesión.' });
                        history.push('/signin');
                    } else {
                        setStep(5);
                        notification.error({ message: 'Código Incorrecto, intenta de nuevo. Si el problema persiste comunicate con nuestro equipo.' });
                    }
                }
            })
            .catch(err => {
                notification.error({ message: 'Servicio no disponible.' });
                setStep(5);
            })
            .finally(() => null);
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <img src={olympiaLogo} style={styles.img} />
                <Title level={2} style={{ textAlign: 'center' }}>Creación de Cuenta</Title>
                {step === 1 && <StepOne initialValues={values[1]} handlerOnSuccess={handlerOnSuccessStep} />}
                {step === 2 && <StepTwo initialValues={values[2]} handlerOnSuccess={handlerOnSuccessStep} />}
                {step === 3 && <StepThree initialValues={values[3]} handlerOnSuccess={handlerOnSuccessStep} />}
                {step === 5 && <StepFive initialValues={values[5]} handlerOnSuccess={handlerOnSuccessStep} />}
                {(step === 4 || step === 6) && (
                    <div style={{ textAlign: 'center', margin: '40px 0 0' }}>
                        <Spin size="large" tip="Espera un momento por favor" />
                    </div>
                )}
            </div>
        </div>
    )
};

const styles = {
    container: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: '#2b669f'
    },
    content: {
        width: '90%',
        maxWidth: '499px',
        backgroundColor: 'white',
        padding: '40px 24px 40px'
    },
    img: {
        width: '60%',
        margin: '0 auto',
        display: 'block'
    }
}

export default SignupScreen;