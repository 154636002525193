import { Row, Col } from 'antd';

import {
    formatPrice,
    getPaymentMethodName,
    dateToString,
    extractIVA,
    substractIVA
} from '../../lib/utils';

const SellDescription = ({ record = {} }) => {

    return (
        <Row gutter={[24, 6]}>
            <Col span={24}>
                <div
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                    }}>
                    <img
                        style={{ width: '55%' }}
                        alt="Gym logo"
                        src={record?.Gym?.logo}
                    />
                </div>

                <p style={{ textAlign: 'center', margin: '12px 0' }}>
                    {record?.Gym?.phoneNumber}<br />
                    {record?.Gym?.rfc} <br />
                    {record?.Gym?.address}
                </p>
            </Col>
            <Col span={24}>
                <span className="bold">Folio:&nbsp;</span>
                <span>{record?.id}</span>
            </Col>
            <Col span={24}>
                <span className="bold">Gimnasio:&nbsp;</span>
                <span>{record?.Gym?.name} - {record?.Gym?.branch}</span>
            </Col>
            <Col span={24}>
                <span className="bold">Fecha:&nbsp;</span>
                <span>{dateToString(record?.createdAt, true)}</span>
            </Col>
            <Col span={24}>
                <span className="bold">Cajero:&nbsp;</span>
                <span>{record?.User?.firstName} {record?.User?.lastName}</span>
            </Col>
            <Col span={24}>
                <span className="bold">Código de Cliente:&nbsp;</span>
                <span>{record?.Client?.code ?? 'PG'}</span>
            </Col>
            <Col span={24}>
                <span className="bold">Nombre de Cliente:&nbsp;</span>
                <span>{record?.Client?.firstName ?? 'Publico'} {record?.Client?.lastName ?? 'General'}</span>
            </Col>
            <Col span={24}>
                <table className="tableItems">
                    <thead>
                        <tr>
                            <th>Descripcion</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th align="right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record?.SellItems?.map((item, index) => {
                            return (
                                <tr key={index.toString()}>
                                    <td>{item?.description ?? 'DESCRIPTION_NOT_FOUND'}</td>
                                    <td>{formatPrice(item?.price)}</td>
                                    <td>{item?.quantity}</td>
                                    <td align="right">{formatPrice(parseFloat(item?.quantity * item?.price).toFixed(2))}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="borderTop">
                            <td colSpan={2}>&nbsp;</td>
                            <td>Sub Total</td>
                            <td align="right">{formatPrice(substractIVA(record?.total, 16).toFixed(2))}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>&nbsp;</td>
                            <td>I.V.A.</td>
                            <td align="right">{formatPrice(extractIVA(record?.total, 16).toFixed(2))}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>&nbsp;</td>
                            <td>Total</td>
                            <td align="right">{formatPrice(record?.total)}</td>
                        </tr>
                        <tr className="borderTop">
                            <td>Forma de pago</td>
                            <td colSpan="3" align="right">{getPaymentMethodName(record?.paymentMethod)}</td>
                        </tr>
                        <tr>
                            <td>Recibido</td>
                            <td colSpan={3} align="right">{formatPrice(record?.payment)}</td>
                        </tr>
                        <tr>
                            <td>Cambio</td>
                            <td colSpan={3} align="right">{formatPrice(record?.change)}</td>
                        </tr>
                    </tfoot>
                </table>
            </Col>
            <Col span={24}>
                <p style={{
                    textAlign: 'center',
                    color: '#a4a4a4',
                }}>Este comprobante no tiene valides para ser usado para fines fiscales.</p>
            </Col>
        </Row>
    )

}

export default SellDescription