import API from '../api';

export const attemptingSignIn = (credentials) => {
    return API().post('/users/signin', credentials);
}

export const attemptingSignUp = (values) => {
    return API().post('/users/signup', values);
}

export const signUpOtp = (values) => {
    return API().post('/users/signupOtp', values);
}

export const createUser = (token, values) => {
    return API(token).post('/users/create', values);
}

export const updateUser = (token, values) => {
    return API(token).post('/users/update', values);
}

export const deleteUser = (token, values) => {
    return API(token).post('/users/delete', values);
}

export const listUser = (token, values) => {
    return API(token).post('/users/list', values);
}