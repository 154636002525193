import { Button, Tag } from 'antd';

import AdminPage from '../../../components/adminPage';
import GymModal from '../../../components/gymModal';

import {
    useHistory,
    useRouteMatch
} from "react-router-dom";

const GymScreen = () => {

    const pageTitle = "Administración de Gimnasios";

    const history = useHistory();
    const { url } = useRouteMatch();

    const renderStatus = (value, record) => {
        let color = 'green';
        let text = 'Activo';
        if (value === 'inactive') {
            color = 'magenta';
            text = 'Inactivo';
        }
        return (<Tag color={color}>{text}</Tag>)
    }

    const renderCtrls = (value, record, index) => {
        return (<Button onClick={() => history.push(`${url}/${record.id}`)}>Editar</Button>);
    }

    const columns = [
        { key: 'name', title: 'Nombre Comercial' },
        { key: 'branch', title: 'Sucursal' },
        { key: 'rfc', title: 'R.F.C.' },
        { key: 'businessName', title: 'Razón Social' },
        { key: 'address', title: 'Dirección' },
        { key: 'phoneNumber', title: 'Teléfono' },
        { key: 'status', title: 'Estatus', render: renderStatus },
        { key: 'controls', title: 'Controles', align: 'right', render: renderCtrls },
    ];

    const endpoint = '/gyms/list';

    return (
        <AdminPage
            pageTitle={pageTitle}
            columns={columns}
            endpoint={endpoint}
            Modal={GymModal}
        />
    );
}

export default GymScreen;