import API from '../api';

export const getGyms = (token, values) => {
    return API(token).post('/gyms/list', values);
}

export const createGym = (token, values) => {
    return API(token).post('/gyms/create', values);
}

export const updateGym = (token, values) => {
    return API(token).post('/gyms/update', values);
}

export const getGymById = (token, values) => {
    return API(token).post(`/gyms/getOne`, values);
}

export const getDetails = (token, values) => {
    return API(token).post(`/gyms/getDetails`, values);
}

export const updateDetails = (token, values) => {
    return API(token).post(`/gyms/updateDetails`, values);
}

export const getImagesGallery = (token, gymId) => {
    return API(token).post(`/gyms/getImagesGallery/${gymId}`);
}

export const deleteImageGallery = (token, imageId) => {
    return API(token).post(`/gyms/deleteImageGallery/${imageId}`);
}