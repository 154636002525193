import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    Button,
} from 'antd';

import { useState, useEffect } from 'react';

const width = 300;
const height = width * 0.75;
const imageStyles = {
    width: `${width}px`,
    height: `${height}px`
};
const labelStyles = {
    position: 'absolute',
    bottom: '4px',
    left: '4px',
    background: '#fa141440',
    color: 'white',
    padding: '5px',
    borderRadius: '5px',
}

const PickImage = ({ onTakePicture, value }) => {

    const [openCam, setOpenCam] = useState(false);
    const [picture, setPicture] = useState(undefined);

    useEffect(() => {
        if (value !== picture) {
            setPicture(value)
        }
    }, [value]);

    const handleOpenCam = () => {
        setOpenCam(true);
    }

    const handleCloseCam = () => {
        setOpenCam(false);
    }

    const handleTakePicture = (base64) => {
        setOpenCam(false);
        setPicture(base64);
        onTakePicture(base64);
    }

    return (
        <div>
            {openCam === true && <WebCam handleCloseCam={handleCloseCam} handleTakePicture={handleTakePicture} />}
            {openCam === false && (
                <div>
                    {picture !== undefined && (
                        <div style={{ ...imageStyles, marginBottom: '4px' }}>
                            <img alt="" src={picture} style={{ ...imageStyles }} />
                        </div>
                    )}
                    <Button type="primary" onClick={handleOpenCam}>Tomar Fotografía</Button>
                </div>
            )}
        </div>
    )
};

const WebCam = ({ handleCloseCam, handleTakePicture }) => {

    let video, stream;

    useEffect(() => {
        startWebCam();
    }, []);

    const startWebCam = () => {
        let mediaDevices = navigator.mediaDevices;
        // Accessing the user camera and video.
        mediaDevices
            .getUserMedia({
                video: true,
                audio: false,
            })
            .then((_stream) => {
                stream = _stream;
                // Changing the source of video to current stream.
                video = document.getElementById("vid");
                video.srcObject = _stream;
                video.addEventListener("loadedmetadata", () => {
                    video.play();
                });
            })
            .catch(alert);
    }

    const stopWebCam = () => {
        stream?.getTracks().forEach(function (track) {
            if (track.readyState === 'live' && track.kind === 'video') {
                track.stop();
            }
        })
    }

    const takePicture = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);
        const data = canvas.toDataURL("image/png");
        stopWebCam();
        handleTakePicture(data);
    }

    const _handleCloseCam = () => {
        stopWebCam();
        handleCloseCam();
    }

    return (
        <div>
            <div style={{ ...imageStyles, marginBottom: '4px', position: 'relative' }}>
                <video style={{ ...imageStyles, objectFit: 'cover' }} id="vid"></video>
                <label style={{ ...labelStyles }}>Da click en capturar para tomar la fotografía</label>
            </div>
            <Button type="primary" danger onClick={_handleCloseCam}>Cancelar</Button>
            &nbsp;
            <Button type="primary" onClick={takePicture}>Capturar</Button>
        </div>
    )
};

export default PickImage;