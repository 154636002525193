import { combineReducers } from "redux"

import authReducer from './auth';
import posReducer from './pos';

const rootReducer = {
    auth: authReducer,
    pos: posReducer
};

export default combineReducers(rootReducer);;