import {
    Col,
    Form,
    Input,
    Select,
    DatePicker
} from 'antd';

const { RangePicker } = DatePicker;

const { Option } = Select;

const GetFields = (fields = []) => {
    return fields.map((field, index) => {
        let Component = (<Input />);
        if (field.options) {
            Component = (
                <Select allowClear>
                    {field.options.map((option, index) => (<Option key={`filter-option-${index.toString()}`} value={option.value}>{option.label}</Option>))}
                </Select>
            )
        }
        if (field.rangePicker) {
            Component = (
                <RangePicker />
            )
        }
        if (field.datePicker) {
            Component = (
                <DatePicker />
            )
        }
        const initialValue = field.initialValue ?? undefined;
        return (
            <Col span={8} key={`filter-field-${index.toString()}`}>
                <Form.Item
                    name={field.key}
                    label={field.title}
                    initialValue={initialValue}
                >
                    {Component}
                </Form.Item>
            </Col>
        )
    });
};

export default GetFields;