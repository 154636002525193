import { Modal, Form, Input, Row, Col, message, Select, notification } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { createUser, updateUser } from '../../lib/service/user';
import { getGyms } from '../../lib/service/gym';
import { showMessageError } from '../../lib/utils';

const ModalC = ({ visible, closeModal, record = {}, reloadTable }) => {

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [gyms, setGyms] = useState([]);
    const { accessToken } = useSelector(state => state.auth);

    useEffect(() => {
        if (visible) {
            if (record?.id)
                form.setFieldsValue({ ...record });
        }
    }, [visible]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        getGyms(accessToken, { filters: { status: 'active' }, pagination: { pageSize: 99 } })
            .then(({ status, data }) => {
                if (status === 200) {
                    setGyms(data.data);
                }
            })
            .catch(() => null);
    }

    const onOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    setConfirmLoading(true);

                    let method = record?.id ? updateUser : createUser;
                    let fields = {
                        user: {
                            ...values,
                            id: record?.id
                        }
                    };
                    let prefix = record?.id ? 'actualizado' : 'creado';

                    const { status, data } = await method(accessToken, fields);
                    setConfirmLoading(false);

                    if (status !== 200) {
                        showMessageError();
                    } else {
                        if (data.error) {
                            if (data.error.code === 'username_already_taken') {
                                notification.error({ message: 'El nombre de usuario ya ha sido usado, por favor selecciona otro.' });
                            }
                        } else {
                            message.success(`El usuario ha sido ${prefix} con éxito.`);
                            onCancel();
                            reloadTable();
                        }
                    }
                } catch (error) {
                    setConfirmLoading(false);
                    showMessageError();
                }
            })
            .catch(info => null);
    }

    const onCancel = () => {
        if (confirmLoading) return;
        form.resetFields();
        closeModal();
    }

    return (
        <Modal
            confirmLoading={confirmLoading}
            visible={visible}
            title="Usuario"
            okText="Continuar"
            cancelText="Cancelar"
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form
                form={form}
                layout="vertical"
                name="userForm"
                initialValues={{ modifier: 'public' }}
            >

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label="Nombre(s)"
                            rules={[{ required: true, message: 'No olvides el Nombre!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label="Apellido(s)"
                            rules={[{ required: true, message: 'No olvides el Apellido!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="phoneNumber"
                            label="Número de Celular"
                            rules={[{ required: true, message: 'No olvides el Número!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="username"
                            label="Usuario"
                            rules={[{ required: true, message: 'No olvides el Usuario!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[{ required: !record?.id, message: 'No olvides la Contraseña!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name="userTypeId" label="Tipo de Usuario" initialValue={4}>
                            <Select
                                style={{ width: '100%' }}
                                options={[
                                    { value: 2, label: 'Administrador' },
                                    { value: 3, label: 'Supervisor' },
                                    { value: 4, label: 'Cajero' },
                                    { value: 6, label: 'Instructor' },
                                    { value: 5, label: 'Control de acceso' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="gymIds"
                            label="Acceso a Gimnasio"
                            rules={[{ required: true, message: 'Selecciona al menos un gimnasio.' }]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                options={gyms.map(({ id, name, branch }) => ({ value: id, label: `${name} - ${branch}` }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
}

export default ModalC;