import { useState, useEffect } from 'react';

import { dateToString } from '../../lib/utils'

const Clock = () => {

    const [date, setDate] = useState(new Date());

    function refreshClock() {
        setDate(new Date());
    }
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    const dateString = dateToString(date);

    return (<label>{date.toLocaleTimeString()} {dateString}</label>)

};

export default Clock;