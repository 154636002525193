import { Button, Divider, message } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import AdminPage from '../../components/adminPage';
import SellModal from '../../components/sellModal';

import { formatPrice, dateToString, showMessageError } from '../../lib/utils/index';
import { cancelSell } from '../../lib/service/sell';
import { listUser } from '../../lib/service/user';

const MembershipScreen = () => {

    const { user, accessToken } = useSelector(state => state.auth);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        attempGetUsers();
    }, []);

    const attempGetUsers = async () => {
        try {
            const { status, data } = await listUser(accessToken, { pagination: { pageSize: 99 } });
            if (status !== 200) return;
            setUsers(data.data);
        } catch (error) {

        }
    }

    const pageTitle = "Ventas";

    const renderGymName = (gym) => {
        return `${gym.name} - ${gym.branch}`;
    }

    const renderCreatedAt = (date) => {
        return dateToString(new Date(date), true);
    }

    const renderUsername = (_, record) => {
        if (record?.paymentMethod === 'online')
            return 'Olympia App';
        return `${user?.firstName} ${user?.lastName}`;
    }

    const renderPaymentMethod = (val) => {
        if (val === 'cash') return 'Efectivo';
        if (val === 'tc') return 'Terminal Bancaria';
        if (val === 'online') return 'Pago en linea';
        else return val;
    }

    const renderTotal = (val) => {
        return formatPrice(val);
    }

    const renderCtrls = (_, record, i, openRecord, showPromiseConfirm, reloadTable) => {
        const options = {
            title: `¿Esta seguro de cancelar la venta con folio: ${record.id}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'Esta es una acción que no se puede deshacer.',
            onOk() {
                return cancelSell(accessToken, { sell: { id: record.id } })
                    .then(({ status, data }) => {
                        if (status !== 200 || data.error) {
                            if (data.error?.code === 'SHIFT_NOT_ACTIVE')
                                message.error('No es posible cancelar estas venta. El turno ha terminado.');
                            else
                                showMessageError();
                            return;
                        }
                        message.success(`La venta ha sido cancelada con éxito.`);
                        reloadTable();
                    })
                    .catch(() => {
                        showMessageError();
                    });
            },
            onCancel() { },
        }
        return (
            <>
                {record.paymentMethod !== 'online' && (
                    <>
                        <Button size='small' danger onClick={() => showPromiseConfirm(options)}>Cancelar</Button>
                        <Divider type="vertical" />
                    </>
                )}
                <Button size='small' onClick={() => openRecord(record)}>Ver</Button>
            </>
        );
    }

    const columns = [
        { key: 'id', title: 'Folio' },
        { key: 'Gym', title: 'Gimnasio', render: renderGymName },
        { key: 'createdAt', title: 'Fecha', render: renderCreatedAt },
        { key: 'User', title: 'Usuario', render: renderUsername },
        { key: 'paymentMethod', title: 'Método de Pago', render: renderPaymentMethod },
        { key: 'total', title: 'Total', render: renderTotal },
        { key: 'controls', title: 'Controles', align: 'right', render: renderCtrls },
    ];

    const filters = [
        {
            key: 'gymId',
            title: 'Gimnasio',
            options: user?.gyms?.map((gym) => {
                return { value: gym.id, label: `${gym.name} - ${gym.branch}` };
            }) ?? []
        },
        {
            key: 'paymentMethod',
            title: 'Método de Pago',
            options: [
                { value: 'cash', label: 'Efectivo' },
                { value: 'tc', label: 'Terminal Bancaria' },
                { value: 'online', label: 'Pago en linea' },
            ]
        },
        {
            key: 'userId',
            title: 'Usuario',
            options: users.map((user, index) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))
        },
        { key: 'createdAt', title: 'Fecha', rangePicker: true },
    ];

    const endpoint = '/sells/list';

    return (
        <AdminPage
            pageTitle={pageTitle}
            columns={columns}
            filters={filters}
            endpoint={endpoint}
            showAddButton={false}
            Modal={SellModal}
        />
    );
}

export default MembershipScreen;