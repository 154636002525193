import { Form, Row, Col, Card, Button, Divider } from 'antd';

import GetFields from './getFields.js';

const AdvancedFilter = ({
    className = "",
    fields = [],
    callbackFunc
}) => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (typeof callbackFunc === 'function') {

            const keys = Object.keys(values);
            const fieldsMap = fields.reduce((acc, value) => ({ ...acc, [value.key]: value }), {});
            for (const key of keys) {
                const value = values[key];
                if (value) {
                    const field = fieldsMap[key];
                    if (field.rangePicker) {

                    }
                }
            }
            callbackFunc(values);
        }
    }

    const resetFields = () => {
        form.resetFields();
    }

    const renderFields = (_fields) => {
        const fields = GetFields(_fields);
        return (
            <Row gutter={24}>{fields}</Row>
        )
    }

    if (!fields.length) return null;

    return (
        <Card bordered={false} className={className}>
            <Form form={form} onFinish={onFinish}>
                {renderFields(fields)}
                <Row>
                    <Col span={24} className="text-right">
                        <Button type="primary" htmlType="submit">Buscar</Button>
                        <Divider type="vertical" />
                        <Button onClick={resetFields}>Limpiar</Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default AdvancedFilter;