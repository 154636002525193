import { Card, Table } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import AdvancedFilter from '../advancedFilter';

import API from '../../lib/api';

const TableComponent = ({
    columns = [],
    filters = [],
    endpoint,
    refresh,
    openRecord,
    showPromiseConfirm,
    reloadTable
}) => {

    const { accessToken } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [appliedFilters, setFilters] = useState({});

    const [pagination, setPagination] = useState({ defaultCurrent: 1, defaultPageSize: 10 });
    const [paginationv2, setPaginationv2] = useState({});
    const [dataSource, setDataSource] = useState([]);

    const memoizedLoadData = useCallback(
        () => {
            if (!endpoint) {
                setDataSource([]);
                return;
            }
            setLoading(true);
            API(accessToken)
                .post(
                    endpoint,
                    {
                        filters: appliedFilters,
                        pagination: {
                            current: paginationv2.current,
                            pageSize: paginationv2.pageSize
                        }
                    }
                )
                .then(({ status, data }) => {
                    if (status !== 200) return;
                    setDataSource(data.data);
                    setPagination({ ...pagination, ...data.pagination });
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [refresh, accessToken, endpoint, appliedFilters, paginationv2],
    );

    const applyFilters = (_filters) => {
        setFilters(_filters);
    }

    const handleTableChange = (pagination, filters, sorters) => {
        setPaginationv2(pagination);
    }

    useEffect(() => {
        memoizedLoadData();
    }, [memoizedLoadData]);

    const tableProps = {
        columns: columns.map(column => {
            let render = undefined;
            if (column.render) {
                render = (text, record, index) => {
                    return column.render(
                        text,
                        record,
                        index,
                        openRecord,
                        showPromiseConfirm,
                        reloadTable
                    );
                }
            }
            return {
                ...column,
                dataIndex: column.key,
                render
            }
        }),
        dataSource,
        rowKey: record => record.id,
        loading,
        pagination,
        onChange: handleTableChange
    };

    return (
        <div>
            <AdvancedFilter className="mb-24" fields={filters} callbackFunc={applyFilters} />
            <Card bordered={false}>
                <Table {...tableProps} />
            </Card>
        </div>
    );
}

export default TableComponent;