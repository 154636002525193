import { Modal, Form, Input, Row, Col, InputNumber, Select, Switch, message } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { createMembership, updateMembership } from '../../lib/service/membership';
import { showMessageError } from '../../lib/utils';
import { getGyms } from './../../lib/service/gym';

const { Option } = Select;

const ModalC = ({ visible, closeModal, record = {}, reloadTable }) => {

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [gyms, setGyms] = useState([]);
    const { accessToken } = useSelector(state => state.auth);

    useEffect(() => {
        if (visible) {
            if (record?.id)
                form.setFieldsValue({
                    ...record,
                    packageInclude: record.packageInclude?.split(',') ?? [],
                    status: record?.status === 'active'
                });
        }
    }, [visible]);

    useEffect(() => {
        loadGym();
    }, []);

    const loadGym = async () => {
        const filters = {
            status: 'active'
        };
        const pagination = {
            pageSize: 99
        };
        getGyms(accessToken, { filters, pagination })
            .then(response => {
                if (response.status === 200) {
                    setGyms(response.data.data.map((gym) => ({ value: gym.id + '', label: `${gym.name} - ${gym.branch}` })));
                }
            })
            .catch(err => null);
    }

    const onOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    setConfirmLoading(true);

                    let method = record?.id ? updateMembership : createMembership;
                    let fields = {
                        membership: {
                            ...values,
                            packageInclude: values.packageInclude.join(','),
                            id: record?.id,
                            status: values.status ? 'active' : 'inactive'
                        }
                    };
                    let prefix = record?.id ? 'actualizada' : 'creada';

                    const { status, data: { error } } = await method(accessToken, fields);
                    setConfirmLoading(false);

                    if (status !== 200) {
                        showMessageError();
                    } else if (error) {
                        message.error(`El código de la Membresia ya existe.`);
                    } else {
                        message.success(`La Membresia ha sido ${prefix} con éxito.`);

                        onCancel();
                        reloadTable();
                    }

                } catch (error) {
                    setConfirmLoading(false);
                    showMessageError();
                }
            })
            .catch(info => null);
    }

    const onCancel = () => {
        form.resetFields();
        closeModal();
    }

    return (
        <Modal
            confirmLoading={confirmLoading}
            visible={visible}
            title="Membresia"
            okText="Continuar"
            cancelText="Cancelar"
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form
                form={form}
                layout="vertical"
                name="userForm"
                initialValues={{ modifier: 'public' }}
            >

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="code"
                            label="Código"
                            rules={[{ required: true, message: 'No olvides el Código!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Nombre"
                            rules={[{ required: true, message: 'No olvides el Nombre!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="price"
                            label="Precio"
                            rules={[{ required: true, message: 'No olvides el Precio!' }]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                disabled={Boolean(record?.id)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="type"
                            label="Tipo de periodicidad"
                            rules={[{ required: true, message: 'No olvides el Tipo!' }]}
                        >
                            <Select allowClear disabled={Boolean(record?.id)}>
                                <Option value="day">Día</Option>
                                <Option value="week">Semana</Option>
                                <Option value="month">Mes</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="quantity"
                            label="Cantidad de periodicidad"
                            rules={[{ required: true, message: 'No olvides la Cantidad!' }]}
                        >
                            <Select allowClear disabled={Boolean(record?.id)}>
                                {[...Array(15).keys()].map((value) => (<Option key={`day-${value.toString()}`} value={value + 1}>{value + 1}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="gymIds"
                            label="Aplica en los siguientes Gimnasios"
                            rules={[{ required: true, message: 'Selecciona almenos 1 gimnasio.' }]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                options={gyms}
                                placeholder="Selecciona almenos un gimnasio"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="packageInclude"
                            label="Que incluye?"
                            rules={[{ required: true, message: 'Es importante saber que incluye.' }]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                tokenSeparators={[',']}
                                placeholder="Utiliza una coma ',' para separar"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="status"
                            label="Estatus"
                            initialValue={true}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="Activa" unCheckedChildren="Inactiva" />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
}

export default ModalC;