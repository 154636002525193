import { Modal, Form, Input, Row, Col, message, Button, Upload } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';

import { createGym, updateGym } from '../../lib/service/gym';
import { showMessageError } from '../../lib/utils';

import ImageDemo from '../imageDemo';

const GymModal = ({ visible, closeModal, record = {}, reloadTable }) => {

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [logo, setLogo] = useState({ src: 'error', file: undefined });
    const { accessToken } = useSelector(state => state.auth);

    useEffect(() => {
        if (visible) {
            if (record?.id) {
                form.setFieldsValue({
                    ...record,
                    // status: record?.status === 'active'
                });
                setLogo({ src: record.logo, file: undefined });
            }
        }
    }, [visible]);

    const onOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                try {
                    setConfirmLoading(true);

                    let data;

                    let fields = {
                        gym: {
                            ...values,
                            id: record?.id,
                            // status: values.status ? 'active' : 'inactive'
                        }
                    };
                    let prefix = record?.id ? 'actualizado' : 'creado';

                    /**
                     * Check logo
                     */
                    if (logo.file) {
                        data = new FormData();
                        data.append("logo", logo.file);
                        data.append("gym", JSON.stringify(fields.gym));
                    } else {
                        data = fields;
                    }

                    let method = record?.id ? updateGym : createGym;

                    const { status, data: { error } } = await method(accessToken, data);
                    setConfirmLoading(false);

                    if (status !== 200) {
                        showMessageError();
                    } else if (error) {
                        message.info(`Haz alcanzado el numero permitido de Gimnasios, actualiza tu membresia.`);
                    } else {
                        message.success(`El Gimnasio ha sido ${prefix} con éxito.`);

                        onCancel();
                        reloadTable();
                    }
                } catch (error) {
                    setConfirmLoading(false);
                    showMessageError();
                }
            })
            .catch(info => null);
    }

    const onCancel = () => {
        if (confirmLoading) return;
        form.resetFields();
        setLogo({ src: 'error', file: undefined });
        closeModal();
    }

    return (
        <Modal
            confirmLoading={confirmLoading}
            visible={visible}
            title="Gimnasio"
            okText="Continuar"
            cancelText="Cancelar"
            onCancel={onCancel}
            onOk={onOk}
        >
            <Form
                form={form}
                layout="vertical"
                name="userForm"
                initialValues={{ modifier: 'public' }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <ImageDemo src={logo.src} />
                        <Upload
                            fileList={[]}
                            onRemove={file => null}
                            beforeUpload={file => {
                                const reader = new FileReader();
                                reader.addEventListener("load", function () {
                                    setLogo({
                                        src: reader.result,
                                        file
                                    });
                                }, false);
                                if (file) reader.readAsDataURL(file);
                                return false;
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Selecciona Logo</Button>
                        </Upload>
                        <br />
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Nombre Comercial"
                            rules={[{ required: true, message: 'No olvides el Nombre!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="branch"
                            label="Sucursal"
                            rules={[{ required: true, message: 'No olvides la Sucursal!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="rfc"
                            label="R.F.C"
                            rules={[{ required: true, message: 'No olvides el R.F.C.!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="businessName"
                            label="Razón Social"
                            rules={[{ required: true, message: 'No olvides la Razón Social!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="phoneNumber"
                            label="Teléfono"
                            rules={[{ required: true, message: 'No olvides el Teléfono!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="address"
                            label="Dirección"
                            rules={[{ required: true, message: 'No olvides la Dirección!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>


                {/* <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="status"
                            label="Estatus"
                            initialValue={true}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" />
                        </Form.Item>
                    </Col>
                </Row> */}

            </Form>
        </Modal >
    );
}

export default GymModal;