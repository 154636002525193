import { Button, Tag } from 'antd';

import AdminPage from '../../../components/adminPage';
import MembershipModal from '../../../components/membershipModal';

const MembershipScreen = () => {

    const pageTitle = "Administración de Membresias";

    const renderPrice = (value) => {
        return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const renderType = (value) => {
        if (value === 'day') return 'Dia';
        if (value === 'week') return 'Semana';
        if (value === 'month') return 'Mes';
    }

    const renderStatus = (value) => {
        let color = 'green';
        let text = 'Activo';
        if (value === 'inactive') {
            color = 'magenta';
            text = 'Inactivo';
        }
        return (<Tag color={color}>{text}</Tag>)
    }

    const renderGyms = (value) => {
        return value.map(gymName => (<div key={`${gymName}`} style={{ marginBottom: '4px' }}><Tag>{gymName}</Tag></div>));
    }

    const renderCtrls = (_, record, index, openRecord) => {
        return (<Button onClick={() => openRecord(record)}>Editar</Button>);
    }

    const columns = [
        { key: 'code', title: 'Código' },
        { key: 'name', title: 'Nombre' },
        { key: 'price', title: 'Precio', render: renderPrice },
        { key: 'quantity', title: 'Duración' },
        { key: 'type', title: 'Tipo', render: renderType },
        { key: 'gyms', title: 'Gimnasios', render: renderGyms },
        { key: 'status', title: 'Estatus', render: renderStatus },
        { key: 'controls', title: 'Controles', align: 'right', render: renderCtrls },
    ];

    const filters = [
        { key: 'code', title: 'Código' },
        {
            key: 'status',
            title: 'Estatus',
            options: [
                { value: 'active', label: 'Activa' },
                { value: 'inactive', label: 'Inactiva' },
            ]
        },
    ];

    const endpoint = '/memberships/list';

    return (
        <AdminPage
            pageTitle={pageTitle}
            columns={columns}
            filters={filters}
            endpoint={endpoint}
            Modal={MembershipModal}
        />
    );
}

export default MembershipScreen;