import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './signin.less';

import { Spin, Button, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { signInUser } from '../../reducers/auth';
import { attemptingSignIn } from '../../lib/service/user';

import { useHistory } from "react-router-dom"

import olympiaLogo from "./../../img/Olympia_Logo-01.svg";

const antIcon = <LoadingOutlined style={{ fontSize: 18, marginLeft: '5px' }} spin />;

const { Text } = Typography;

const SignIn = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setErrorMsg("");
        let username = document.getElementById('username').value;
        let password = document.getElementById('password').value;

        // remove extra spaces
        username = username.trim();
        password = password.trim();

        // basic validations
        if (username === "" || password === "") {
            setErrorMsg("Usuario / Contraseña incorrecta");
            return;
        }

        // try signin user
        setLoading(true);
        await attemptingSignIn({ username, password })
            .then(resp => {
                setLoading(false);
                const { data, status } = resp;
                if (status !== 200) {
                    setErrorMsg("Usuario / Contraseña incorrecta");
                    return;
                }
                dispatch(signInUser({
                    user: data.user,
                    accessToken: data.accessToken,
                    shift: data.shift
                }));
            })
            .catch(err => {
                console.log(err);
                setErrorMsg("Usuario / Contraseña incorrecta");
                setLoading(false);
            });
    }

    return (
        <div id="signInContainer">
            <div className="background"></div>
            <div className="form">
                <form onSubmit={onSubmit}>
                    <img src={olympiaLogo} style={styles.img} />
                    <h2>Bienvenido!</h2>
                    <label>Usuario</label>
                    <input id="username" type="text" placeholder="Usuario" required disabled={loading} />
                    <label>Contraseña</label>
                    <input id="password" type="password" placeholder="Contraseña" required disabled={loading} />
                    {errorMsg && <label className="errorMsg">{errorMsg}</label>}
                    <Button htmlType='submit' type="primary" size='large' block disabled={loading}>
                        {loading ? "Validando Información" : "Iniciar Sesión"}
                        <Spin spinning={loading} indicator={antIcon} />
                    </Button>
                    {/* <div style={{ marginTop: '12px' }}>
                        <Text type="secondary">¿No tienes una cuenta?</Text>
                    </div>
                    <Button
                        style={{ marginTop: '12px' }}
                        block size="large" type="dashed"
                        onClick={() => {
                            history.push('/auth/signup');
                        }}>
                        Regístrate
                    </Button> */}

                </form>
            </div>
        </div>
    );
}
const styles = {
    img: {
        width: '80%',
        margin: '0 auto',
        display: 'block'
    }
}

export default SignIn;