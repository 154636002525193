import { useRef, useState } from 'react';
import { Modal } from 'antd';

import PageWrapper from "../pageWrapper";
import Table from '../table';

const { confirm } = Modal;

const AdminPage = ({
    pageTitle = '',
    columns = [],
    filters = [],
    endpoint = '',
    Modal,
    EnableRefOnModal = false,
    showAddButton = true
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [record, setRecord] = useState({});
    const [refresh, doRefresh] = useState(0);
    const modalRef = useRef();

    const addButton = showAddButton ? () => openRecord(true) : null;

    const closeModal = () => {
        setIsModalOpen(false);
        setRecord({});
    }

    const openRecord = (record) => {
        setRecord(record);
        setIsModalOpen(true);
        if (EnableRefOnModal) {
            console.log('a');
            modalRef?.current?.open(record);
        }
    }

    const showPromiseConfirm = (options) => {
        confirm(options);
    }

    const reloadTable = () => {
        doRefresh(prev => prev + 1)
    };

    const renderModal = () => {
        if (!Modal) return

        if (EnableRefOnModal) {
            return (
                <Modal
                    ref={modalRef}
                    onCreateCallback={reloadTable}
                />
            )
        }

        return (
            <Modal visible={isModalOpen} closeModal={closeModal} record={record} reloadTable={reloadTable} />
        )
    }

    return (
        <PageWrapper
            title={pageTitle}
            addButton={addButton}
        >
            {renderModal()}
            <Table
                columns={columns}
                filters={filters}
                endpoint={endpoint}
                refresh={refresh}
                openRecord={openRecord}
                showPromiseConfirm={showPromiseConfirm}
                reloadTable={reloadTable}
            />
        </PageWrapper>
    )
}

export default AdminPage;