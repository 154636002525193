import axios from 'axios';
import CONF from '../../conf';

const API = (token = undefined) => {
    const headers = {};

    if (token)
        headers['Authorization'] = 'Bearer ' + token;

    const instance = axios.create({
        baseURL: CONF.API_URL,
        timeout: 7000,
        headers
    });
    return instance;
}

export default API;