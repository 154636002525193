import API from '../api';

export const createMembership = (token, values) => {
    return API(token).post('/memberships/create', values);
}

export const updateMembership = (token, values) => {
    return API(token).post('/memberships/update', values);
}

export const updateClientMembership = (token, values) => {
    return API(token).post('/memberships/updateClientMembership', values);
}

export const list = (token, values) => {
    return API(token).post('/memberships/list', values);
}