import './index.less';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signOutUser } from '../../reducers/auth';

import Menu from './menu';
import CreateUser from './createUser';

const KioskoApp = () => {

    const dispatch = useDispatch();
    const [screen, setScreen] = useState('menu');

    const returnToApp = () => {
        dispatch(signOutUser());
        // TO-DO
        // si el usuario es cajero entonces terminamos sesion
        // en caso contrario regresar al app
        // history.push('/');
    }

    if (screen === 'menu')
        return <Menu goToScreen={setScreen} returnToApp={returnToApp} />
    if (screen === 'createUser')
        return <CreateUser goToScreen={setScreen} />

};

export default KioskoApp;