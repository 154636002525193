import './editMembership.less';

import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import API from '../../lib/api';
import { formatPrice } from '../../lib/utils';

const EditMembership = ({
    visible,
    onClose,
    onOk
}) => {

    const { accessToken } = useSelector(state => state.auth);

    const [memberships, setMemberships] = useState([]);
    const [membershipSelected, setMembershipSelected] = useState(0);

    const handleCancel = () => {
        setMembershipSelected(0);
        onClose();
    }

    const switchMembership = () => {
        let membership = memberships.filter(x => x.id === membershipSelected)[0];
        if (!membership) {
            alert('POR FAVOR SELECCIONE UNA MEMBRESIA');
            return;
        }
        // return to menu and popup sell modal
        handleCancel();
        onOk(membership);
    }

    useEffect(() => {
        API(accessToken)
            .post('memberships/list', { pagination: { pageSize: 100 } })
            .then(({ status, data }) => {
                if (status === 200) {
                    setMemberships(data.data);
                }
            })
            .catch(err => null);
    }, []);

    return (
        <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            width={720}
        >
            <div className="editMembership">
                {memberships.map((item, index) => {
                    return (
                        <div
                            key={index.toString()}
                            className={`membership ${item.id === membershipSelected ? 'active' : ''}`}
                            onClick={() => {
                                setMembershipSelected(item.id === membershipSelected ? 0 : item.id);
                            }}
                        >
                            <label>{item.code}</label>
                            <label>{item.name}</label>
                            <label>{formatPrice(item.price)}</label>
                        </div>
                    )
                })}
            </div>
            <div className="btnSection">
                <button
                    type="button"
                    onClick={switchMembership}
                >
                    Continuar
                </button>
            </div>
        </Modal>
    )
};

export default EditMembership;